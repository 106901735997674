import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { order as orderStatusDictionary } from '~constants/order';
import { ReactComponent as ExternalLinkAltRegular } from '~images/icons/external-link-alt-regular.svg';
import { ReactComponent as PlusCircle } from '~images/icons/plus-circle-regular.svg';
import { orderDeliveryStatusNames } from '~server-types/doc/api/models/order';
import { checkIsUnassignCourierAllowed } from '~shared/utils/checkIsUnassignCourierAllowed';
import courierName from '~shared/utils/courierName';
import { escapeHtml } from '~shared/utils/escapeHtml';
import type { Couriers } from '~types/couriers';

import type { OrderWithExtra } from '../Yandex/YandexOrdersMap';
import { useStyles } from './styles';

type PlacemarkBalloonHeaderProps = {
	orderId: string;
	docNumber: string;
};

const PlacemarkBalloonHeader = ({ orderId, docNumber }: PlacemarkBalloonHeaderProps) => {
	const { cx, classes } = useStyles();

	return (
		<button type="button" className={cx(`order-button-${orderId}`, classes.balloonHeader)}>
			{docNumber}
			<ExternalLinkAltRegular width={11} height={11} />
		</button>
	);
};

export const createPlacemarkBalloonHeader = ({ orderId, docNumber }: PlacemarkBalloonHeaderProps) => {
	return renderToString(<PlacemarkBalloonHeader orderId={orderId} docNumber={docNumber} />);
};

type PlacemarkBalloonBodyProps = {
	order: OrderWithExtra;
	courier: Couriers.CourierBrief | undefined;
};

const PlacemarkBalloonBody = ({ order, courier }: PlacemarkBalloonBodyProps) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<div>
			<div>
				<div>
					<span>{t('Статус:')}</span>
					<span className={classes.rowData}>
						{order.status ? escapeHtml(orderStatusDictionary[order.status]) : t('Нет статуса')}
					</span>
				</div>
				<div>
					<span>{t('Статус доставки:')}</span>
					<span className={classes.rowData}>
						{order.eda_status ? escapeHtml(orderDeliveryStatusNames[order.eda_status]) : t('Нет статуса')}
					</span>
				</div>
				<div>
					<span>{t('Курьер:')}</span>
					<span className={classes.rowData}>
						{courier ? (
							escapeHtml(courierName(courier, { showEatsId: false }))
						) : order.dispatch_delivery_type === 'yandex_taxi' ? (
							t('Доставка на такси')
						) : order.courier?.name ? (
							order.courier.name
						) : checkIsUnassignCourierAllowed() ? (
							<button type="button" id={`assign-button-${order.order_id}`} className={classes.assignButton}>
								<PlusCircle width={12} height={12} />
								<span className={classes.assignButtonTitle}>{t('Назначить')}</span>
							</button>
						) : (
							t('Нет курьера')
						)}
					</span>
				</div>
			</div>
			{order.isBatched && order.batchedWith ? (
				<>
					<div>{t('Другие заказы курьера')}</div>
					{order.batchedWith.map((num) => (
						<div key={num}>{num}</div>
					))}
				</>
			) : (
				''
			)}
		</div>
	);
};

export const createPlacemarkBalloonBody = ({ order, courier }: PlacemarkBalloonBodyProps) => {
	return renderToString(<PlacemarkBalloonBody order={order} courier={courier} />);
};

export const getBallonOpenCallback = ({
	orderId,
	setUnassignCourierModalOrderId,
	setOrderModalOrderId,
}: {
	orderId: string;
	setUnassignCourierModalOrderId?: (orderId?: string) => void;
	setOrderModalOrderId?: (orderId?: string) => void;
}) => {
	return () => {
		const assignCourierButton = document.getElementById(`assign-button-${orderId}`);
		// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
		assignCourierButton?.addEventListener('click', () => setUnassignCourierModalOrderId?.(orderId));

		const orderButtons = Array.from(document.querySelectorAll<HTMLButtonElement>(`.order-button-${orderId}`));

		orderButtons.forEach((button) => {
			const isInYandexClustererMenu = !!button.closest('[class$="-cluster-tabs__menu"]');

			if (isInYandexClustererMenu) {
				button.style.fontSize = '14px';
				button.style.fontWeight = 'unset';

				const buttonIcon = button.querySelector('svg');

				if (buttonIcon) {
					buttonIcon.style.display = 'none';
				}
			} else {
				// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
				button.addEventListener('click', () => setOrderModalOrderId?.(orderId));
			}
		});
	};
};

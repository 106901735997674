import { Alert } from 'antd';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from '~/i18n';
import { checkProjectsFields } from '~constants/dataPageFields/checkProjectsFields';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type * as FieldTypes from '~constants/dataPageFields/types';
import DataSection from '~shared/components/DataForm/DataSection';
import { useStyles as useFormStyles } from '~shared/components/DataForm/styles';
import DayIntervalField from '~shared/components/Fields/RepeatScheduleField/DayInterval';
import WeekDaysField from '~shared/components/Fields/RepeatScheduleField/WeekDays';
import { Field } from '~shared/components/Forms';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useCheckPermitGroup } from '~zustand/userData';

import type { CheckProjectFormValues } from '../types';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
	userCompanyTitle?: string;
}

const sectionsToFields = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: ['title', 'company_id', 'check_project_type'],
	},
	{
		label: tDynamic('Повтор и период'),
		fields: [
			'repeat_type',
			'repeat_weekdays',
			'repeat_interval',
			'threshold',
			'timetable_time',
			'once_date_time',
			'begin_date',
			'has_finish',
			'end_date',
			'next_time',
		],
	},
	{
		label: tDynamic('Дополнительная информация'),
		fields: ['vars.created_by', 'vars.last_edited_by', 'vars.approved_by', 'vars.declined_by'],
	},
]);

const field = (key: string) => getField(checkProjectsFields, i18n.t('Проект ЛИ'), key);

export const MainSection = ({ addMode, editMode = false, userCompanyTitle }: Props) => {
	const { classes: formClasses } = useFormStyles({});
	const form = useFormContext<CheckProjectFormValues>();
	const [t] = useTranslation();
	const isPermitGroupUserJoinCompany = useCheckPermitGroup('group_user_join_company');

	const [createdValue, updatedValue, varsValue, repeatTypeValue, beginDate] = useWatch({
		control: form.control,
		name: ['created', 'updated', 'vars', 'repeat_type', 'begin_date', 'products'],
	});

	const created = field('created');
	if (created) {
		created.hidden = editMode;
	}

	const companyIdField = field('company_id') as FieldTypes.IdReplacerField | undefined;
	if (companyIdField) {
		companyIdField.hidden = addMode;
	}

	const dateFormat = 'DD.MM.YYYY HH:mm';

	const author = field('vars.created_by') as FieldTypes.IdReplacerField | undefined;
	if (author) {
		author.hidden = editMode;
		author.additional = {
			content: dayjs(createdValue).format(dateFormat),
			dataTest: 'data form Дата создания',
		};
	}

	const editor = field('vars.last_edited_by') as FieldTypes.IdReplacerField | undefined;
	if (editor) {
		editor.hidden = editMode;
		editor.additional = {
			content: dayjs(updatedValue).format(dateFormat),
			dataTest: 'data form Дата изменения',
		};
	}

	const approver = field('vars.approved_by') as FieldTypes.IdReplacerField;
	if (approver) {
		approver.hidden = editMode || !varsValue?.approved_by;
	}

	const decliner = field('vars.declined_by') as FieldTypes.IdReplacerField;
	if (decliner) {
		decliner.hidden = editMode || !varsValue?.declined_by;
	}

	const repeatWeekdays = field('repeat_weekdays');
	if (repeatWeekdays) {
		repeatWeekdays.element = (
			<Field
				id="repeat_weekdays"
				addMode={addMode}
				editMode={editMode}
				name="repeat_weekdays"
				component={WeekDaysField}
				label={repeatWeekdays.label}
			/>
		);
	}

	const repeatInterval = field('repeat_interval');
	if (repeatInterval) {
		repeatInterval.element = (
			<Field
				id="repeat_interval"
				addMode={addMode}
				editMode={editMode}
				name="repeat_interval"
				component={DayIntervalField}
				label={repeatInterval.label}
			/>
		);
	}

	const endDate = field('end_date') as FieldTypes.CalendarField;
	if (endDate) {
		endDate.disabledDate = (date) => date.isBefore(dayjs(beginDate));
	}

	const next = field('next_time');
	if (next) {
		next.hidden = editMode || repeatTypeValue === 'no';
	}

	const sections = sectionsToFields.map((section) => ({
		label: section.label,
		fields: checkProjectsFields[0].fields.filter((field) => section.fields.includes(field.key)),
	}));

	return (
		<>
			{addMode && isPermitGroupUserJoinCompany && userCompanyTitle && (
				<Alert
					message={t('Проект будет создан для компании {{ company }}', {
						company: userCompanyTitle,
					})}
					type="info"
					showIcon
					data-test="check project add alert"
				/>
			)}
			{sections.map((section) => (
				<DataSection
					key={section.label}
					section={section}
					colspan={{
						xl: { span: 6 },
						lg: { span: 8 },
						sm: { span: 12 },
						xs: { span: 12 },
					}}
					classes={formClasses}
					editMode={editMode}
					addMode={addMode}
				/>
			))}
		</>
	);
};

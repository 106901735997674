import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Roles } from '~types/roles';
import type { UserRoles } from '~types/user';

export const getRoleName = (data: Roles.RoleType): UserRoles | string =>
	data.tanker_key ? data.tanker_key : data.name;

export const getRoleNames = (data: Roles.RoleType | Roles.RoleType[] | undefined) => {
	if (!data) {
		return;
	}
	return makeDynamicTranslations(
		(Array.isArray(data) ? data : [data]).reduce((result, role) => {
			result[role.role_id] = tDynamic(getRoleName(role));

			return result;
		}, {})
	);
};

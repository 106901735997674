import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TeamIcon } from '~images/icons/team.svg';
import type { UserData } from '~shared/components/MenteeMentorField/MentorsField';
import SingleMenteeItem from '~shared/components/MenteeMentorField/SingleMenteeItem';
import { useStyles } from '~shared/components/MenteeMentorField/styles';

const MenteesBlock = ({
	currentMentees,
	onAssignClick,
	assignPermit,
}: {
	currentMentees: (UserData & { user_id: string })[];
	assignPermit: boolean;
	onAssignClick: (value: string) => void;
}) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<div>
			<Alert
				data-test="mentor alert"
				className={classes.alert}
				icon={<TeamIcon height={16} width={16} className={classes.alertIcon} />}
				message={
					<>
						<div className={classes.alertHeader}>{t('Кладовщик - наставник')}</div>
						<div className={classes.alertText}>{t('Может помогать кладовщикам-новичкам адаптироваться')}</div>
					</>
				}
				type="info"
				showIcon
				closable
			/>
			{currentMentees?.map((mentee) => {
				return (
					<SingleMenteeItem
						key={mentee.user_id}
						mentee={mentee}
						assignPermit={assignPermit}
						onAssignClick={onAssignClick}
					/>
				);
			})}
		</div>
	);
};

export default MenteesBlock;

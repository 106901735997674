import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { UserData } from '~shared/components/MenteeMentorField/MentorsField';
import { useStyles } from '~shared/components/MenteeMentorField/styles';
import userName from '~shared/utils/userName';

const { Panel } = Collapse;

const ExMenteesBlock = ({ exMentees }: { exMentees: UserData[] }) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<Collapse className={classes.containerCollapse}>
			<Panel
				key="Panel"
				header={<span className={classes.collapseHeader}>{t('Бывшие подопечные')}</span>}
				className={classes.containerCollapse}
			>
				{exMentees?.map((mentee) => {
					return (
						<div key={mentee.user_id} className={classes.menteeContainer}>
							<Link to={`/stores/${mentee.store_id}/users/${mentee.user_id}`}>{userName(mentee)}</Link>
							<div className={classes.menteeTime}>
								{t('Подопечный c {{dateFrom}} по {{dateTo}}', {
									dateTo: dayjs(mentee.vars?.mentoring_until).format('DD.MM.YYYY'),
									dateFrom: dayjs(mentee.vars?.mentoring_since).format('DD.MM.YYYY'),
								})}
							</div>
						</div>
					);
				})}
			</Panel>
		</Collapse>
	);
};

export default ExMenteesBlock;

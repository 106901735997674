import { PageHeaderButton } from '@lavka/ui-kit';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import type { JSX } from 'react';

import { ReactComponent as EllipsisHRegular } from '~images/icons/ellipsis-h-regular.svg';
import { EllipsisRegularIcon } from '~shared/ui-kit/icons';
import Icon, { DownOutlined } from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from './styles';

export interface MenuButton {
	key?: string | number;
	component: JSX.Element;
	condition?: boolean;
}

export type DotsButtonsButton =
	| MenuButton[]
	| {
			section: string;
			buttons: MenuButton[];
			condition?: boolean;
			dataTest?: string;
	  }[];

interface Props {
	buttons: DotsButtonsButton;
	dataTest?: string;
	text?: string;
	inline?: boolean;
	className?: string;
	tooltip?: { title: string; placement?: TooltipPlacement };
	icon?: JSX.Element;
	bordered?: boolean;
	onVisibleChange?: (visible: boolean) => void;
	inHeader?: boolean;
}

const DotsButton = ({
	text,
	buttons,
	dataTest,
	inline,
	className,
	tooltip,
	icon,
	bordered = false,
	onVisibleChange,
	inHeader,
}: Props) => {
	const { cx, classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const menu = () => {
		const buttonsList: JSX.Element[] = [];
		const pushButtons = (list: any[], button: any, index: number) => {
			if (button.condition === undefined || button.condition) {
				list.push(
					<div key={button.key || index} className={classes.block}>
						{button.component}
					</div>
				);
			}
		};
		buttons.forEach((button: any, index) => {
			if (button.hasOwnProperty('section')) {
				if (button.condition === undefined || button.condition) {
					const sectionButtonsList: JSX.Element[] = [];
					button.buttons.forEach((sectionButton: any, index: number) =>
						pushButtons(sectionButtonsList, sectionButton, index)
					);
					if (sectionButtonsList.length) {
						buttonsList.push(
							<div key={button.section} data-test={button.dataTest}>
								<p className={classes.sectionTitle}>{button.section}</p>
								{sectionButtonsList}
							</div>
						);
					}
				}
			} else {
				pushButtons(buttonsList, button, index);
			}
		});
		return buttonsList;
	};

	const menuList = menu();

	if (!menuList.length) {
		return null;
	}

	const dropdown = (
		<Dropdown
			overlay={<Menu className={classes.menu}>{menuList}</Menu>}
			trigger={['click']}
			onOpenChange={onVisibleChange}
		>
			{inHeader ? (
				<PageHeaderButton
					variant="icon"
					view="header-accent"
					data-test={dataTest || 'dots button'}
					iconLeft={<EllipsisRegularIcon width={20} height={20} />}
					iconRight={text && <DownOutlined />}
				>
					{text}
				</PageHeaderButton>
			) : (
				<Button
					className={cx(className, {
						[classes.button]: !text,
						[commonClasses.btnIcon]: inline,
						[classes.buttonBordered]: bordered,
					})}
					data-test={dataTest || 'dots button'}
				>
					{text ? text : icon ? icon : <Icon component={EllipsisHRegular} />}
					{text && <DownOutlined />}
				</Button>
			)}
		</Dropdown>
	);

	if (tooltip) {
		return <Tooltip {...tooltip}>{dropdown}</Tooltip>;
	}

	return dropdown;
};

export default DotsButton;

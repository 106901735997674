import { useEffect, useRef } from 'react';

import { useCouriersBriefByExternalId } from '~cache/useCouriersBriefByExternalId';
import { useYandexMapDarkTheme } from '~shared/hooks/useYandexMapDarkTheme';
import { YmapsInitializer } from '~shared/utils/ymapsInitializer';
import type { Zones } from '~types/zones';

import { statusStringColorDictionary } from '../../pageConfig';
import { createPlacemarkBalloonBody, createPlacemarkBalloonHeader, getBallonOpenCallback } from '../Balloon';
import type { OrderWithCoords } from '../index';
import { getPreset, getShortNumber } from '../utils';

declare let ymaps: any;

export interface OrderWithExtra extends OrderWithCoords {
	isBatched?: boolean;
	batchedWith?: string[];
}

type Props = {
	mapState: {
		center: number[];
		zoom: number;
		controls: string[];
	};
	orders: Record<string, OrderWithExtra>;
	zone: Zones.Zone | null;
	setUnassignCourierModalOrderId?: (orderId?: string) => void;
	setOrderModalOrderId?: (orderId?: string) => void;
};

const YandexOrdersMap = ({ mapState, orders, zone, setUnassignCourierModalOrderId, setOrderModalOrderId }: Props) => {
	const mapContainer = useRef<HTMLDivElement | null>(null);
	const map = useRef<ymaps.Map | null>(null);
	const clusterer = useRef<any>();
	const ordersOnMap = useRef({} as Record<string, ymaps.Placemark>);
	const couriersBriefByExternalId = useCouriersBriefByExternalId([]);

	useEffect(() => {
		map.current = new ymaps.Map(mapContainer.current, mapState, {
			autoFitToViewport: 'always',
		});
		const storePoint = new ymaps.Placemark(mapState.center, {}, { preset: 'islands#redCircleDotIconWithCaption' });
		map.current?.geoObjects.add(storePoint);

		clusterer.current = new ymaps.Clusterer({
			clusterIconLayout: 'default#pieChart',
			clusterIconPieChartRadius: 25,
			clusterIconPieChartCoreRadius: 10,
			clusterIconPieChartStrokeWidth: 3,
			clusterDisableClickZoom: false,
			clusterOpenBalloonOnClick: true,
			clusterBalloonContentLayoutWidth: 360,
			clusterBalloonLeftColumnWidth: 120,
		});

		map.current?.geoObjects.add(clusterer.current);

		return () => map.current?.destroy();
	}, []);

	useEffect(() => {
		if (zone) {
			const storeZone = new ymaps.Polygon(
				zone.zone.geometry.coordinates[0],
				{},
				{ fillColor: '#389E0D40', strokeColor: '#389E0D90' }
			);
			map.current?.geoObjects.add(storeZone);
		}
	}, [zone]);

	useEffect(() => {
		Object.values(orders).forEach((order) => {
			if (!order || !order.coordinates) {
				return;
			}

			const balloonHeader = createPlacemarkBalloonHeader({
				orderId: order.order_id,
				docNumber: `${order.isBatched ? '🔗 ' : ''}${order.doc_number}`,
			});

			const balloonBody = createPlacemarkBalloonBody({
				order,
				courier: order.courier?.taxi_driver_uuid
					? couriersBriefByExternalId[order.courier.taxi_driver_uuid]
					: undefined,
			});

			const onBalloonOpen = getBallonOpenCallback({
				orderId: order.order_id,
				setUnassignCourierModalOrderId,
				setOrderModalOrderId,
			});

			if (order.order_id in ordersOnMap.current) {
				ordersOnMap.current[order.order_id].options.set('preset', getPreset('yandex', order.eda_status));
				ordersOnMap.current[order.order_id].properties.set('balloonContentHeader', balloonHeader);
				ordersOnMap.current[order.order_id].properties.set('balloonContentBody', balloonBody);
				ordersOnMap.current[order.order_id].properties.set(
					'iconContent',
					`${order.isBatched ? '🔗 ' : ''}${getShortNumber(order.doc_number)}`
				);
			} else {
				const orderPoint = new ymaps.Placemark(
					order.coordinates,
					{
						balloonContentHeader: balloonHeader,
						balloonContentBody: balloonBody,
						iconContent: `${order.isBatched ? '🔗 ' : ''}${getShortNumber(order.doc_number)}`,
					},
					{
						preset: getPreset('yandex', order.eda_status),
						iconColor: statusStringColorDictionary[order.eda_status],
						balloonPanelMaxMapArea: 0,
					}
				);

				ordersOnMap.current[order.order_id] = orderPoint;
				clusterer.current.add(orderPoint);

				clusterer.current.balloon.events.add(['open', 'click'], onBalloonOpen);
			}

			ordersOnMap.current[order.order_id].events.add('balloonopen', onBalloonOpen);

			if (ordersOnMap.current[order.order_id].balloon.isOpen()) {
				onBalloonOpen();
			}
		});

		Object.keys(ordersOnMap.current).forEach((orderId) => {
			if (!(orderId in orders)) {
				clusterer.current.remove(ordersOnMap.current[orderId]);
				delete ordersOnMap.current[orderId];
			}
		});
	}, [orders]);

	useYandexMapDarkTheme(map.current, [map]);

	return <div data-test="map" ref={mapContainer} style={{ inlineSize: '100%', blockSize: '500px' }} />;
};

const YandexOrdersMapWrapped = (props: Props) => (
	<YmapsInitializer>
		<YandexOrdersMap {...props} />
	</YmapsInitializer>
);

export default YandexOrdersMapWrapped;

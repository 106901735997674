import { notification } from '@lavka/ui-kit';
import { Alert, Spin } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import api from '~/api';
import ModalComponent from '~shared/components/ModalComponent';
import type { CheckProjects } from '~types/checkProjects';
import type { Timetable } from '~types/common';

import { actionsConfig } from './pageConfig';

type Props = {
	modalOpen: string | null;
	toggleModalOpen: (value: string | null) => void;
	loading: boolean;
	toggleLoading: (value: boolean) => void;
	projectModifiedId: string | null;
	setProjectModifiedId: (value: string | null) => void;
	checkProject: CheckProjects.CheckProject | undefined;
	updateCheckProject: (value: { result: CheckProjects.CheckProject }) => void;
};

const CheckProjectActionModal = ({
	modalOpen,
	toggleModalOpen,
	loading,
	toggleLoading,
	projectModifiedId,
	setProjectModifiedId,
	checkProject,
	updateCheckProject,
}: Props) => {
	const [t] = useTranslation();
	const allowedStatuses = [400];
	const errorMessages = {
		'Target products must be archive': t('Старый продукт должен быть в статусе "Архивный"'),
		'Products have different groups': t('Продукты из разных групп'),
		'Products have different products_scope': t('Продукты из разных бизнес-кластеров'),
	};

	const navigate = useNavigate();

	useEffect(() => {
		if (modalOpen === 'edit') {
			toggleModalOpen(null);
			setProjectModifiedId(null);
			navigate(`/check_projects/edit/${projectModifiedId}`);
		}
	}, [modalOpen, projectModifiedId]);

	const doAction = async (action: string | null, id: string | null) => {
		if (!action || !id || !checkProject) return;
		const updCheckProject = { ...checkProject } as any;
		switch (action) {
			case 'send_to_approve':
				updCheckProject.status = 'waiting_approve';
				break;
			case 'approve':
				updCheckProject.status = 'active';
				break;
			case 'edit':
				navigate(`/check_projects/edit/${id}`);
				return;
			case 'make_copy':
				delete updCheckProject.check_project_id;
				delete updCheckProject.created;
				delete updCheckProject.updated;
				delete updCheckProject.vars;
				delete updCheckProject.lsn;
				delete updCheckProject.serial;
				delete updCheckProject.company_id;
				if (updCheckProject.schedule.end === null) {
					delete updCheckProject.schedule.end;
				}
				if (updCheckProject.schedule.timetable.length) {
					updCheckProject.schedule.timetable.forEach((elem: Timetable) => {
						// Для типа day_interval параметры repeat и start обязательны
						if (elem.type !== 'day_interval') {
							delete elem.repeat;
							delete elem.start;
						}
					});
				}
				updCheckProject.status = 'draft';
				updCheckProject.external_id = uuidv4();
				updCheckProject.title = `${t('Копия')} ${updCheckProject.title}`;
				break;
			case 'disable':
				updCheckProject.status = 'disabled';
				break;
			case 'remove':
				updCheckProject.status = 'removed';
				break;
			case 'disapprove':
				updCheckProject.status = 'draft';
				break;
		}

		toggleLoading(true);
		try {
			const { data } = await api.checkProjects.save(updCheckProject, {
				disableDefaultNotification: allowedStatuses,
				disableExternalLog: allowedStatuses,
			});

			updateCheckProject(data);
			notification.success({
				message: t('Статус проекта обновлен'),
			});
		} catch (e) {
			notification.error({
				message: allowedStatuses.includes(e.status)
					? // @ts-ignore
						(errorMessages[e.data.message] ?? e.data.message)
					: t('Не удалось обновить статус проекта'),
			});
		} finally {
			toggleLoading(false);
		}
	};

	// Почему-то visible ниже не отрабатывает нормально
	if (!modalOpen) {
		return null;
	}

	return (
		<ModalComponent
			open={!!modalOpen}
			onCancel={() => toggleModalOpen(null)}
			onOk={async () => {
				await doAction(modalOpen, projectModifiedId);
				toggleModalOpen(null);
				setProjectModifiedId(null);
			}}
			title={typeof modalOpen === 'string' ? actionsConfig[modalOpen].headerText : ''}
			cancelText={t('Отменить')}
			okText={loading ? <Spin /> : typeof modalOpen === 'string' ? actionsConfig[modalOpen].buttonText : t('OK')}
			data-test={`check_project action modal ${modalOpen}`}
			zIndex={1001}
		>
			<p>{typeof modalOpen === 'string' ? actionsConfig[modalOpen].mainText : ''}</p>
			{typeof modalOpen === 'string' && actionsConfig[modalOpen].alertText && (
				<Alert message={actionsConfig[modalOpen || ''].alertText} type="warning" showIcon />
			)}
		</ModalComponent>
	);
};

export default CheckProjectActionModal;

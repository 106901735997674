export const COLSPAN = {
	xl: { span: 12 },
	lg: { span: 12 },
	sm: { span: 12 },
	xs: { span: 24 },
};

export const userRoles = [
	'tv_device',
	'free_device',
	'guest',
	'authen_guest',
	'courier',
	'contractor',
	'kitchen_contractor',
	'cofee_machine_contractor',
	'picker',
	'executer',
	'executer_junior',
	'barcode_executer',
	'stocktaker',
	'head_executer',
	'quality_control_manager',
	'vice_store_admin',
	'store_admin',
	'kitchen_manager',
	'dc_admin',
	'support',
	'support_head',
	'telemarketing',
	'company_support',
	'support_ro',
	'company_support_ro',
	'support_it',
	'company_support_it',
	'support_courier',
	'company_support_courier',
	'supervisor',
	'head_supervisor',
	'expansioner_ro',
	'expansioner',
	'category_manager',
	'company_category_manager',
	'kitchen_category_manager',
	'territorial_kitchen_manager',
	'content_manager',
	'city_head',
	'employee_audit',
	'chief_audit',
	'company_chief_audit',
	'courier_manager',
	'dispatcher',
	'staff_courier_manager',
	'company_staff_courier_manager',
	'finance_manager',
	'courier_company_admin',
	'courier_admin',
	'company_courier_admin',
	'logistic_admin',
	'company_admin',
	'company_admin_ro',
	'chief_manager',
	'company_chief_manager',
	'analyst',
	'company_analyst',
	'admin_ro',
	'admin',
] as const;

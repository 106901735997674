import { Button } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '~images/icons/user-add.svg';
import AssignMentee from '~shared/components/MenteeMentorField/AssignMentee';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import ExMenteesBlock from '~shared/components/MenteeMentorField/ExMenteesBlock';
import MenteesBlock from '~shared/components/MenteeMentorField/MenteesBlock';
import UnAssignMentee from '~shared/components/MenteeMentorField/UnAssignMentee';
import type { User } from '~types/user';
import { useCheckPermit } from '~zustand/userData';

import { useStyles } from './styles';

export type UserData = Partial<User.User> & { user_id: string };

const MentorsField = ({
	user,
	menteesData,
	usersData,
	updateRerenderKey,
}: {
	user?: UserData;
	menteesData: UserData[];
	usersData: UserData[];
	updateRerenderKey: () => void;
}) => {
	const { classes } = useStyles();
	const [visible, setModalVisible] = useState(false);
	const [menteeToUnAssign, setMenteeToUnAssign] = useState('');
	const [t] = useTranslation();

	const currentMentees = menteesData.filter(
		(mentee) => dayjs().isBefore(dayjs(mentee?.vars?.mentoring_until).add(1, 'day')) && mentee.status === 'active'
	);

	const exMentees = menteesData.filter(
		(mentee) => dayjs().isAfter(dayjs(mentee?.vars?.mentoring_until).add(1, 'day')) || mentee.status !== 'active'
	);

	const usersList = usersData.filter((exec) => {
		const isNotSelf = exec.user_id !== user?.user_id;
		const isNotMentoredBySelf = exec.mentor_id !== user?.user_id;
		const isExec = ['executer_junior', 'executer'].includes(exec.role);
		const canReassign =
			exec.vars?.mentoring_until &&
			dayjs(exec.vars?.mentoring_since).add(MILESTONES.canReAssign, 'day').isAfter(dayjs());
		const canAssign =
			!exec.vars?.mentoring_until && dayjs(exec.created).add(MILESTONES.canAssign, 'day').isAfter(dayjs());

		return isNotSelf && isExec && (canAssign || canReassign) && isNotMentoredBySelf;
	});

	const assignPermit = useCheckPermit('users_save');

	return (
		<>
			<UnAssignMentee
				visible={!!menteeToUnAssign}
				setModalUnAssignMentee={setMenteeToUnAssign}
				menteeId={menteeToUnAssign}
				updateRerenderKey={updateRerenderKey}
			/>
			<AssignMentee
				userId={user?.user_id}
				usersData={usersList}
				visible={visible}
				setModalVisible={setModalVisible}
				updateRerenderKey={updateRerenderKey}
			/>
			<div data-test="mentees container" className={classes.container}>
				<div className={classes.headerContainer}>
					<div className={classes.header}>{t('Подопечные-новички')}</div>
					<Button
						data-test="add mentee button"
						className={classes.addButton}
						type="primary"
						ghost
						onClick={() => setModalVisible(true)}
					>
						<AddIcon />
						{t('Добавить')}
					</Button>
				</div>
				<MenteesBlock currentMentees={currentMentees} assignPermit={assignPermit} onAssignClick={setMenteeToUnAssign} />

				{!!exMentees?.length && <ExMenteesBlock exMentees={exMentees} />}
			</div>
		</>
	);
};

export default MentorsField;

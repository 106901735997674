import { Alert } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import NewbieAlert from '~shared/components/MenteeMentorField/MenteeAlert';
import { useStyles } from '~shared/components/MenteeMentorField/styles';
import type { User } from '~types/user';

const MenteeAlert = ({ editUser }: { editUser?: User.User }) => {
	const { classes } = useStyles();
	const [t] = useTranslation();

	const startDate = dayjs(editUser?.mentor_id ? editUser?.vars.mentoring_since : editUser?.created);

	const canAssignMentor =
		editUser && !editUser.mentor_id && startDate.add(MILESTONES.canAssign, 'days').isAfter(dayjs());

	const canReAssignMentor =
		editUser &&
		!!editUser.mentor_id &&
		dayjs(editUser.vars.mentoring_since ?? '')
			.add(MILESTONES.canReAssign, 'days')
			.isAfter(dayjs());

	const changeMentorIsLate =
		editUser &&
		(!!editUser.mentor_id
			? dayjs(editUser.vars.mentoring_since ?? '')
					.add(MILESTONES.canReAssign, 'days')
					.isBefore(dayjs())
			: dayjs(editUser.created).add(MILESTONES.canAssign, 'days').isBefore(dayjs()));

	return (
		<>
			<NewbieAlert />

			{canAssignMentor && (
				<Alert
					className={classes.alert}
					message={
						<div className={classes.alertHeader}>
							{t('Выберите наставника до {{date}} включительно', {
								date: startDate.add(MILESTONES.canAssign - 1, 'day').format('DD.MM.YYYY'),
							})}
						</div>
					}
					type="warning"
					showIcon
					data-test="mentor info alert assign"
				/>
			)}

			{canReAssignMentor && (
				<Alert
					className={classes.alert}
					message={
						<>
							<div className={classes.alertHeader}>
								{t('Изменить наставника можно до {{date}} включительно', {
									date: startDate.add(MILESTONES.canAssign - 1, 'days').format('DD.MM.YYYY'),
								})}
							</div>
						</>
					}
					type="info"
					showIcon
					data-test="mentor warning alert"
				/>
			)}

			{changeMentorIsLate && (
				<Alert
					className={classes.alert}
					message={
						<>
							<div className={classes.alertHeader}>{t('Изменить наставника уже не получится')}</div>
						</>
					}
					type="warning"
					showIcon
					data-test="mentor warning alert"
				/>
			)}
		</>
	);
};

export default MenteeAlert;

import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Stores } from '~types/stores';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<{ title?: string; company_id?: string }, Stores.Cluster>('/api/admin/clusters/list'),
	load: makeLoadFn<Stores.Cluster, 'cluster_id'>('/api/admin/clusters/load', 'cluster_id'),
	save: (
		data: Pick<
			Partial<Stores.Cluster>,
			| 'cluster_id'
			| 'eda_region_id'
			| 'tanker_id'
			| 'zone'
			| 'geoarea'
			| 'tz'
			| 'lang'
			| 'currency'
			| 'region_id'
			| 'courier_shift_setup'
			| 'courier_service_shift_setup'
			| 'courier_shift_underage_setup'
			| 'dispatch_setup'
			| 'publish_setup'
			| 'disabled_role_permits'
			| 'map_layer'
			| 'billing_agglomeration'
			| 'attr'
		> & { title: string }
	) => axios.post<{ result: Stores.Cluster }>('/api/admin/clusters/save', data),

	loadRegionsMapping: (data: { cluster_id: string }) =>
		axios.post<{ result?: Stores.RegionsMappingResponse | null }>('/api/admin/clusters/load_regions_mapping', data),
};

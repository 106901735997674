import { Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { UserData } from '~shared/components/MenteeMentorField/MentorsField';
import { useStyles } from '~shared/components/MenteeMentorField/styles';
import { DeleteOutlined } from '~shared/utils/icons';
import userName from '~shared/utils/userName';

const SingleMenteeItem = ({
	mentee,
	assignPermit,
	onAssignClick,
}: {
	mentee: UserData;
	assignPermit: boolean;
	onAssignClick: (value: string) => void;
}) => {
	const { classes } = useStyles();
	const [t] = useTranslation();

	return (
		<div data-test={`mentee container ${mentee.user_id}`} key={mentee.user_id} className={classes.menteeContainer}>
			<div>
				<Link data-test="mentee link" to={`/stores/${mentee.store_id}/users/${mentee.user_id}`}>
					{userName(mentee)}
				</Link>
				<div className={classes.menteeTime}>
					{t('Подопечный до: {{date}}', { date: dayjs(mentee.vars?.mentoring_until).format('DD.MM.YYYY') })}
				</div>
			</div>
			<Button
				data-test="delete mentee"
				disabled={!assignPermit}
				ghost
				shape="circle"
				icon={<DeleteOutlined />}
				style={{ border: 'none' }}
				className={classes.btnIcon}
				onClick={() => {
					onAssignClick(mentee.user_id);
				}}
			/>
		</div>
	);
};

export default SingleMenteeItem;

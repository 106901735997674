/* eslint-disable max-lines */

import {
	accountingTypesNames,
	accountingTypesValues,
	productScheduleTypesNames,
	productScheduleTypesValues,
	productStatusesNames,
	productStatusesValues,
} from '~server-types/doc/api/models/product';
import { shelfTagsNames, shelfTagsValues } from '~server-types/doc/api/models/shelf';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';

import { measures, units } from '../measures';
import { productsScope, productsScopeDictionary } from '../other';
import { pricePattern } from '../patterns';
import { productTypes } from '../productTypes';
import { warehouseGroupList } from '../warehouseGroupList';
import type { SectionConfig } from './types';

export const productsFields = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'product_id',
				label: tDynamic('Ключ при сохранении существующей записи'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
				hidden: true,
			},
			{
				key: 'external_id',
				label: tDynamic('Внешний ID продукта'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'long_title',
				label: tDynamic('Название продукта'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'title',
				label: tDynamic('Короткое название'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'warehouse_group_list',
				label: tDynamic('Складская группа'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				canEdit: false,
				dictionary: warehouseGroupList,
				options: Object.keys(warehouseGroupList),
			},
			{
				key: 'status',
				label: tDynamic('Статус'),
				hidden: false,
				type: 'string',
				inputType: 'select',
				canEdit: true,
				hasLock: true,
				options: productStatusesValues,
				dictionary: productStatusesNames,
			},
			{
				key: 'description',
				label: tDynamic('Описание продукта'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'private_label',
				label: tDynamic('Собственная торговая марка'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'products_scope',
				label: tDynamic('Бизнес-кластер'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				canEdit: false,
				defaultValue: [],
				options: productsScope,
				dictionary: productsScopeDictionary,
			},
			{
				key: 'legal_restrictions',
				label: tDynamic('Набор ограничений'),
				type: 'select-multi',
				inputType: 'tag',
				canEdit: true,
				defaultValue: [],
				options: ['RU_18+'],
			},
			{
				key: 'barcode',
				label: tDynamic('Массив штрихкодов'),
				type: 'other',
				inputType: 'other',
				canEdit: false,
				hidden: false,
			},
			{
				key: 'gtin',
				label: tDynamic('GTIN'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'images',
				label: tDynamic('Массив урлов картинок'),
				type: 'other',
				inputType: 'other',
				canEdit: false,
				hidden: true,
			},
			{
				key: 'order',
				label: tDynamic('Порядок отображения'),
				type: 'string',
				inputType: 'text',
				hidden: true,
				canEdit: true,
			},
			{
				key: 'ttl_show',
				label: tDynamic('Количество минут для разобрали'),
				type: 'string',
				inputType: 'number',
				canEdit: true,
			},
			{
				key: 'vars.imported.leftover_for_visual_control',
				label: tDynamic('Остаток для проведения визуального контроля'),
				type: 'string',
				inputType: 'number',
				canEdit: false,
			},
			{
				key: 'groups',
				label: tDynamic('Группы продуктов'),
				type: 'select-multi',
				inputType: 'tree',
				canEdit: true,
				parentPropName: 'parent_group_id',
				idPropName: 'group_id',
				titlePropName: 'name',
				path: 'product_groups',
				showAllParents: true,
				params: {
					allowClear: true,
					multiple: true,
					showSearch: true,
				},
				activeStatuses: ['active'],
				defaultValue: [],
				options: [],
				data: [],
			},
			{
				key: 'ingredients',
				label: tDynamic('Состав'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'weight',
				label: tDynamic('Вес (нетто)/объем'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'vars.imported.netto_weight',
				label: tDynamic('Вес нетто'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'vars.imported.brutto_weight',
				label: tDynamic('Вес брутто'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'vars.imported.true_mark',
				label: tDynamic('Честный знак'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
			},
			{
				key: 'vars.imported.nomenclature_type',
				label: tDynamic('Тип номенклатуры'),
				type: 'string',
				inputType: 'text',
				canEdit: true,
			},
			{
				key: 'quants',
				label: tDynamic('Порции'),
				type: 'number',
				inputType: 'number',
				canEdit: true,
			},
			{
				key: 'quant_unit',
				label: tDynamic('Единицы измерения порций'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				canEdit: true,
				options: Object.keys(units),
				dictionary: units,
			},
			{
				key: 'is_quantized',
				label: tDynamic('Наличие кванта в ПИМ'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: false,
			},
			{
				key: 'upper_weight_limit',
				label: tDynamic('Максимум веса в группе'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'lower_weight_limit',
				label: tDynamic('Минимум веса в группе'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'type_accounting',
				label: tDynamic('Тип обработки'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: accountingTypesValues,
				dictionary: accountingTypesNames,
				canEdit: false,
			},
			{
				key: 'parent_id',
				label: tDynamic('Родитель'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'is_meta',
				label: tDynamic('Мета-товар'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'valid',
				label: tDynamic('Срок хранения'),
				type: 'string',
				inputType: 'number',
				canEdit: true,
			},
			{
				key: 'write_off_before',
				label: tDynamic('Списывать за'),
				type: 'string',
				inputType: 'number',
				canEdit: true,
			},
			{
				key: 'sizes',
				label: tDynamic('Габариты (высота/ширина/глубина)'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'measure_unit',
				label: tDynamic('Единицы измерения остатков'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				hidden: false,
				options: Object.keys(measures),
				dictionary: measures,
			},
			{
				key: 'vat',
				label: tDynamic('НДС'),
				type: 'string',
				inputType: 'text',
				pattern: pricePattern,
				canEdit: true,
			},
			{
				key: 'plu_code',
				label: tDynamic('PLU-код'),
				type: 'string',
				inputType: 'text',
			},
			{
				key: 'tags',
				label: tDynamic('Тип хранения'),
				type: 'select-multi',
				inputType: 'tag',
				canEdit: true,
				defaultValue: [],
				options: shelfTagsValues,
				dictionary: shelfTagsNames,
			},
			{
				key: 'schedule_type',
				label: tDynamic('Расписание'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: productScheduleTypesValues,
				dictionary: productScheduleTypesNames,
				allowClear: true,
			},
			{
				key: 'price',
				label: tDynamic('Цены'),
				type: 'other',
				inputType: 'other',
				canEdit: false,
				hidden: false,
				pattern: pricePattern,
				priceTypes: {
					store: tDynamic('Цена (базовая)'),
					markdown: tDynamic('Цена (уценка)'),
				},
			},
			{
				key: 'in_assortment',
				label: tDynamic('Наличие в ассортименте'),
				type: 'other',
				inputType: 'other',
				canEdit: false,
				hidden: false,
			},
			{
				key: 'created',
				label: tDynamic('Создан'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
				renderLog: formatTimeAndYear,
			},
			{
				key: 'updated',
				label: tDynamic('Обновлён'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
				renderLog: formatTimeAndYear,
			},
			{
				key: 'schedule_type',
				label: tDynamic('Тип продукта'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				canEdit: true,
				options: Object.keys(productTypes),
				dictionary: productTypes,
			},
			{
				key: 'vars.imported.logistic_tags',
				label: tDynamic('Логистические теги'),
				type: 'select-multi',
				inputType: 'tag',
				canEdit: false,
				tagView: true,
				viewOnly: true,
				tagClassName: 'tag-view-only',
				emptyPlaceholder: tDynamic('Нет'),
				hidden: true,
			},
		],
	},
	{
		label: tDynamic('Пищевая (Энергетическая) ценность в 100 г'),
		fields: [
			{
				key: 'calorie',
				label: tDynamic('Калорийность'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'fat',
				label: tDynamic('Жиры'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'carbohydrate',
				label: tDynamic('Углеводы'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
			{
				key: 'protein',
				label: tDynamic('Протеины'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
			},
		],
	},
	{
		label: tDynamic('Остатки'),
		fields: [
			{
				key: 'stocks',
				label: tDynamic('Остатки'),
				type: 'array',
				inputType: 'other',
				canEdit: false,
				hidden: true,
			},
		],
	},
] as SectionConfig[]);

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const SamplesPage = lazy(() => import('./index'));
const SamplesDataPage = lazy(() => import('./SamplesDataPage'));

const samples = makeRoutes([
	{
		path: '/samples',
		permitOneOf: ['samples_seek'],
		element: <SamplesPage />,
	},
	{
		path: '/samples/add',
		permitOneOf: ['samples_create'],
		element: <SamplesDataPage editMode addMode />,
	},
	{
		path: '/samples/edit/:id',
		permitOneOf: ['samples_save'],
		element: <SamplesDataPage editMode />,
	},
	{
		path: '/samples/:id',
		permitOneOf: ['samples_load'],
		element: <SamplesDataPage />,
	},
]);

export default samples;

import type { ExtraWayPoints } from '~server-types/doc/api/models/order';
import { makeStyles } from '~styles/theme';

export const mainBackground = '#0F1B26';
export const blocksBackground = '#29313B';
export const numberBackground = '#20262C';
export const mainText = '#8FAFBF';
export const columnBackground = 'rgba(143,175,191, 0.03)';
export const headerColor = '#17181A';
export const headerHoverColor = '#3D3F3F';
export const darkRed = '#4D363D';
export const darkYellow = '#4D4536';
export const lightRed = 'rgba(255, 149, 128, 0.2)';
export const accentRed = '#CC5B5B';
export const surgeViolet = '#441466';
export const surgeText = '#C81EFA';
export const tagBackground = 'rgba(89, 108, 128, 0.2)';
export const tagText = 'rgba(255, 255, 255, 0.7)';
export const detailText = 'rgba(255, 255, 255, 0.8)';

export const EXTRA_WAY_POINTS_COLORS: Record<ExtraWayPoints, string> = {
	pharmacy: '#65C2A9',
	item_pick_up_point: '#FFDD00',
	pet_store: '#FF7C1E',
	dark_kitchen: '#FFDD00',
};

export const useStyles = makeStyles()((theme) => ({
	tableauContainer: {
		fontSize: '1vw',
		fontWeight: 500,
		display: 'block',
		blockSize: '100vh',
		minInlineSize: 1200,
		backgroundColor: mainBackground,
		overflow: 'hidden',
		'@media (max-width: 1200px)': {
			fontSize: '0.8em',
		},
	},
	alignRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tableauGrid: {
		inlineSize: '100%',
		blockSize: '100%',
		display: 'grid',
		gridTemplateColumns: '4fr minmax(0, 1fr)',
		gridTemplateRows: '32px 1fr 15fr', // должно быть '50px 1fr 9fr'
		gap: '5px 12px',
		gridTemplateAreas: `'header header'
						'averages averages'
						'kanban delivery'`, // вторая строка должна быть averages delivery
		paddingInline: 8,
	},
	headerCell: {
		backgroundColor: blocksBackground,
		borderRadius: 12,
		color: theme.text.white,
		fontSize: 14,
		blockSize: '3em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	columnNumber: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBlock: 5,
		paddingInline: 10,
		blockSize: 30,
		marginInlineStart: 15,
		backgroundColor: numberBackground,
		borderRadius: 7,
		color: theme.text.white,
	},
	redCard: {
		backgroundColor: darkRed,
		'&.mobile': {
			backgroundColor: '#FFEAE5',
		},
	},
	redTime: {
		'.mobile &, :not(.mobile)': {
			color: accentRed,
		},
	},
	avatar: {
		gridArea: 'avatar',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		inlineSize: '1.5em',
		blockSize: '1.5em',
		fontSize: 16,
		borderRadius: '50%',
		backgroundColor: 'rgba(255, 255, 255, 0.1)',
		'.mobile &': {
			backgroundColor: '#E6E6E6',
			svg: {
				fill: '#1C1D20',
			},
			'&.courierAssigned': {
				svg: {
					fill: '#CCE2E3',
				},
			},
		},
	},
	avatarTaxi: {
		svg: {
			fill: theme.text.black,
		},
	},
	initials: {
		gridArea: 'avatar',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		inlineSize: '100%',
		blockSize: '100%',
		borderRadius: '50%',
		color: theme.text.white,
		fontSize: '0.9em',
	},
	time: {
		overflow: 'hidden',
		'.mobile &': {
			color: '#1C1D20',
		},
	},
}));

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const LegalEntitiesPage = lazy(() => import('../components/LegalEntities'));
const LegalEntitiesDataPage = lazy(() => import('../components/LegalEntities/LegalEntitiesDataPage'));

const legalEntitiesRoutes = makeRoutes([
	{
		path: '/legal_entities',
		permitOneOf: ['legal_entities_list', 'group_legal_entities_create'],
		element: <LegalEntitiesPage />,
	},
	{
		path: '/legal_entities/add',
		permitOneOf: ['legal_entities_create'],
		element: <LegalEntitiesDataPage editMode addMode />,
	},
	{
		path: '/legal_entities/edit/:legal_entity_id',
		permitOneOf: ['legal_entities_save'],
		element: <LegalEntitiesDataPage editMode />,
	},
	{
		path: '/legal_entities/:legal_entity_id',
		permitOneOf: ['legal_entities_load'],
		element: <LegalEntitiesDataPage />,
	},
]);

export default legalEntitiesRoutes;

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const EquipmentDictionary = lazy(() => import('../components/Equipment/Dictionary'));

const equipmentDictionary = makeRoutes([
	{
		path: '/equipment_dictionary',
		permitOneOf: ['equipment_types_seek', 'equipment_types_save'],
		exp: 'exp_lavka4',
		element: <EquipmentDictionary />,
	},
]);

export default equipmentDictionary;

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const PriceListsPage = lazy(() => import('../components/PriceListsPage'));
const PriceListDataPage = lazy(() => import('../components/PriceListsPage/PriceListsDataPage'));

const priceListsDraftsRoutes = makeRoutes([
	{
		path: '/price_lists_drafts',
		permitOneOf: ['draft_price_lists_seek'],
		element: <PriceListsPage drafts />,
	},
	{
		path: '/price_lists_drafts/add',
		permitOneOf: ['draft_price_lists_create'],
		element: <PriceListDataPage editMode addMode drafts />,
	},
	{
		path: '/price_lists_drafts/edit/:price_list_id',
		permitOneOf: ['draft_price_lists_save'],
		element: <PriceListDataPage editMode drafts />,
	},
	{
		path: '/price_lists_drafts/:price_list_id',
		permitOneOf: ['draft_price_lists_load'],
		element: <PriceListDataPage drafts />,
	},
]);

export default priceListsDraftsRoutes;

import { notification } from '@lavka/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { EntityName } from '~cache/apiLoadProperties';
import SingleSelect from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect/SingleSelect';
import { checkOnlyView } from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect/utils';
import { useDebounce } from '~shared/hooks/useDebounce';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';
import { useCheckPermit } from '~zustand/userData';

import { apiLoadProperties } from '../../../../../multiSelect/initMultiSelect';
import type { SelectFieldProps } from '../../SelectField';
import MultiSelect from './MultiSelect';

const UnifiedMultiSelect = (
	props: SelectFieldProps & {
		selectEntity: EntityName;
		searchDataProps?: Record<string, string | undefined>;
		mode?: 'multiple';
	}
) => {
	const [t] = useTranslation();

	const {
		onlyView,
		editMode,
		fieldRequired,
		disabled,
		selectEntity,
		options: fixedOptions,
		dictionary: initialDictionary,
		loading,
		searchDataProps,
		mode,
	} = props;
	const [dictionary, setDictionary] = useState<Record<string, string>>(initialDictionary ?? {});
	const [options, setOptions] = useState<string[]>(fixedOptions ?? []);
	const [searchData, setSearchData] = useState(searchDataProps);
	const [isLoading, setIsLoading] = useState(false);
	const entityProperties = apiLoadProperties[selectEntity];
	const debouncedSearchData = useDebounce(searchData, 300);
	const isPermitList = useCheckPermit(entityProperties?.listPermit ?? '');
	const isPermitLoad = useCheckPermit(entityProperties?.loadPermit ?? '');
	useEffect(() => {
		if (fixedOptions?.length) {
			setOptions(fixedOptions);
			setDictionary(initialDictionary ?? {});
		}
		const skip = isObjectEmpty(entityProperties) || fixedOptions?.length || !isPermitList || !editMode;

		if (skip) return;

		const controller = new AbortController();
		(async () => {
			try {
				//@ts-expect-error
				if (!entityProperties.listFunc) {
					return;
				}
				setIsLoading(true);
				//@ts-expect-error
				const { data } = await entityProperties.listFunc(debouncedSearchData, { signal: controller.signal });
				setDictionary((prevState) =>
					data.results?.reduce(
						(dict: typeof dictionary, element: any) => {
							const id = element[entityProperties.idField];
							dict[id] = entityProperties.title(element) ?? id;
							return dict;
						},
						{ ...prevState }
					)
				);

				// Опции храним отдельно от словаря, тк опции - это результат текущей загрузки.
				// Словарь - это результат текущей и всех прошлых загрузок.
				// Это нужно чтобы одновременно хранить и словарь для ранее выбранных элементов и показывать в дропдауне только свежезагруженные элементы

				setOptions((data.result ?? data.results ?? []).map((element: any) => element[entityProperties.idField]));
			} catch (e) {
				if (e.status !== 'CANCELED') {
					notification.error({
						message: t('Не удалось получить данные'),
					});
				}
			} finally {
				setIsLoading(false);
			}
		})();
		return () => {
			controller.abort();
		};
	}, [selectEntity, debouncedSearchData, fixedOptions]);

	//TODO: заменить title на searchField в onSearch

	const fieldDisabled = disabled || (!!entityProperties?.listPermit && !isPermitList);

	// если есть пермит только на load, то в editMode показываем как во viewMode

	const onlyViewProp =
		onlyView ||
		checkOnlyView({
			editMode,
			listPermit: entityProperties?.listPermit,
			loadPermit: entityProperties?.loadPermit,
		});

	const displaySelect = isPermitList || isPermitLoad || fixedOptions;

	if (!displaySelect) {
		return null;
	}

	if (mode === 'multiple') {
		return (
			<MultiSelect
				{...props}
				onlyView={onlyViewProp}
				fieldRequired={fieldRequired}
				loading={loading || isLoading}
				disabled={disabled || fieldDisabled}
				searchDataProps={searchDataProps}
				setSearchData={setSearchData}
				dictionary={dictionary}
				options={options}
				fixedOptions={fixedOptions}
			/>
		);
	}

	return (
		<SingleSelect
			{...props}
			onlyView={onlyViewProp}
			fieldRequired={fieldRequired}
			loading={loading || isLoading}
			disabled={fieldDisabled}
			searchDataProps={searchDataProps}
			setSearchData={setSearchData}
			dictionary={dictionary}
			options={options}
			fixedOptions={fixedOptions}
			setDictionary={setDictionary}
		/>
	);
};

export default UnifiedMultiSelect;

import { notification } from '@lavka/ui-kit';
import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { ResponseError } from '~types/common';
import type * as Markings from '~types/markings';

export default {
	list: makeListFn<Markings.ListRequest, Markings.TrueMark>('/api/admin/true_marks/list'),
	trueApiCheck: (data: Markings.TrueApiCheckRequest, config?: AxiosAdditionalConfig) =>
		axios
			.post<{
				result: {
					v3_cises_info: { data: Markings.TrueMarkApiV3[]; err: ResponseError };
					v4_codes_check: { data: Markings.TrueMarkApiV4; err: ResponseError };
				};
			}>('/api/admin/true_marks/true_api_check', data, config)
			.then(({ data }) => {
				const message = data.result.v3_cises_info.err?.message ?? data.result.v4_codes_check.err?.message;
				if (message) notification.error({ message });

				return { data };
			}),
};

import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const BonusDataPage = lazy(() => import('../components/TariffPlans/Bonuses/BonusDataPage'));

const bonusesRoutes = makeRoutes([
	{
		path: '/bonuses/add',
		element: <PrependPath type="cluster" />,
	},
	{
		path: '/clusters/:cluster_id/bonuses/add',
		permitOneOf: ['bonus_create'],
		element: <BonusDataPage editMode addMode />,
	},
	{
		path: '/bonuses/edit/:bonus_id',
		permitOneOf: ['bonus_save'],
		element: <BonusDataPage editMode />,
	},
	{
		path: '/bonuses/:bonus_id',
		permitOneOf: ['bonus_load'],
		element: <BonusDataPage />,
	},
]);

export default bonusesRoutes;

import type { BackendModule, ReadCallback } from 'i18next';

import type { Language } from './types';
import { getMatchedLanguage } from './utils';

export const DynamicImportBackend: BackendModule = {
	type: 'backend',
	init: () => {},
	create: () => {},
	read(language: Language, _, callback: ReadCallback) {
		const { isMatched, language: matchedLanguage } = getMatchedLanguage(language);

		if (!isMatched) {
			console.error(`Unsupported language: ${language}. Falling back to en-US`);
		}

		import(`./translations/${matchedLanguage}.json`)
			.then((resources) => {
				callback(null, resources);
			})
			.catch((error) => {
				callback(error, false);
			});
	},
};

import api from '~/api';
import { useCache } from '~cache/useCache';
import { getRoleNames } from '~shared/utils/getRoleName';

import useLoadData from './useLoadData';

export const useGetUserRoles = () => {
	const cache = useCache({ roles: [] });
	const { data } = useLoadData(
		() => api.roles.list(),
		[],
		undefined,
		(data) => {
			cache.addData({
				entityName: 'roles',
				data: data.results,
			});
		}
	);

	if (!data) {
		return {};
	}

	return getRoleNames(data?.results);
};

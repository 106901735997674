import type { JSX } from 'react';

import i18n from '~/i18n';
import {
	orderCheckValidModeNames,
	orderCheckValidModeValues,
	orderTypeNames,
} from '~server-types/doc/api/models/order';
import {
	schetTaskCreateCreateCheckValidOrderTypeValues,
	schetTaskStatusNames,
	schetTaskStatusValues,
} from '~server-types/doc/api/models/schets';
import { intervalViewText } from '~shared/components/Fields/SchetsSchedule/utils';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import type { Schets } from '~types/schets';

import type { SectionConfig } from './types';

export const schetsTask = makeDynamicTranslations({
	crons_healthcheck: tDynamic('Отправка метрики в Solomon'),
	create_check_valid: tDynamic('Создание документа на проверку сроков годности'),
	create_writeoff: tDynamic('Создание документа на списание'),
	sync_products: tDynamic('Синхронизация продуктов'),
	update_vat: tDynamic('Обновление НДС'),
	sync_price_lists: tDynamic('Синхронизация прайс-листов'),
	calc_salaries: tDynamic('Расчёт ЗП'),
	replenish_kitchen_assortment: tDynamic('Пополнение ассортимента кухни'),
	robot_replenishment: tDynamic('Cнабжение робозоны'),
});

export type SchetTaskHandlerType = keyof typeof schetsTask;

export const schetsStatusColorDictionary = makeDynamicTranslations({
	paused: '',
	pending: 'purple',
	delayed: 'blue',
	running: 'green',
	deleted: 'volcano',
});

export const schetTaskBasicFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Основное'),
		fields: [
			{
				key: 'company_id',
				label: tDynamic('Компания'),
				type: 'string',
				inputType: 'id',
				entity: 'companies',
				titleField: 'title',
				permit: 'companies_seek',
				path: 'companies',
			},
			{
				key: 'store_id',
				label: tDynamic('Склад'),
				type: 'string',
				inputType: 'text',
				canEdit: false,
				hidden: true,
				onlyView: true,
			},
			{
				key: 'scope',
				label: '',
				type: 'string',
				inputType: 'text',
				canEdit: false,
				hidden: true,
			},
			{
				key: 'handler',
				label: tDynamic('Задание'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: [],
				dictionary: schetsTask,
				canEdit: true,
			},
			{
				key: 'status',
				label: tDynamic('Статус'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: schetTaskStatusValues,
				dictionary: schetTaskStatusNames,
				onlyView: true,
			},
			{
				key: 'prev_run_at',
				label: tDynamic('Последний запуск'),
				type: 'string',
				inputType: 'calendar',
				onlyView: true,
				params: {
					onlyDate: false,
					withSeconds: true,
					alwaysShowYear: true,
				},
			},
			{
				key: 'next_run_at',
				label: tDynamic('Следующий запуск'),
				type: 'string',
				inputType: 'calendar',
				onlyView: true,
				params: {
					onlyDate: false,
					withSeconds: true,
					alwaysShowYear: true,
				},
			},
			{
				key: 'approved_by',
				label: tDynamic('Подтверждено'),
				type: 'string',
				inputType: 'id',
				path: 'users',
				entity: 'userExecutors',
				titleField: ['last_name', 'first_name'],
				permit: 'users_load',
				textEmpty: true,
			},
			{
				key: 'approved_at',
				label: tDynamic('Время подтверждения'),
				type: 'string',
				inputType: 'calendar',
				onlyView: true,
				params: {
					onlyDate: false,
					withSeconds: true,
					alwaysShowYear: true,
				},
			},
			{
				key: 'tz',
				label: tDynamic('Таймзона'),
				type: 'string',
				inputType: 'text',
				onlyView: true,
			},
		],
	},
	{
		label: tDynamic('Расписание'),
		fields: [
			{
				key: 'schedule',
				label: tDynamic('Расписание'),
				type: 'other',
				inputType: 'other',
				canEdit: true,
				renderLog: (schedule: Required<Schets.Schet>['schedule']): JSX.Element => {
					return (
						<>
							{schedule?.start_time && (
								<div>
									{i18n.t('Дата и время начала {{date}}', { date: formatTimeAndYear(schedule?.start_time) ?? '—' })}
								</div>
							)}
							{schedule?.interval && <div>{intervalViewText(schedule.interval)}</div>}
						</>
					);
				},
			},
		],
	},
]);

export const schetTaskCreateCheckValid: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Дополнительно'),
		fields: [
			{
				key: 'kwargs.order_type',
				label: tDynamic('Тип документа'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: schetTaskCreateCreateCheckValidOrderTypeValues,
				dictionary: orderTypeNames,
				canEdit: true,
			},
			{
				key: 'kwargs.mode',
				label: tDynamic('Режим проверки'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: orderCheckValidModeValues,
				dictionary: orderCheckValidModeNames,
				canEdit: true,
			},
		],
	},
]);

const syncProductOptions = ['pigeon', 'pigeon_chef', 'pigeon_nana', 'pigeon_nana_aws'];

export const schetTaskCreateSyncProducts: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Дополнительно'),
		fields: [
			{
				key: 'kwargs.bird_name',
				label: tDynamic('Имя ПИМа для синхронизации'),
				type: 'multiple-select',
				inputType: 'multiple-select',
				options: syncProductOptions,
				canEdit: true,
			},
		],
	},
]);

const salaryCalculationNames = [
	'russia_main',
	'russia_region',
	'israel_selfemployed',
	'israel_neto',
	'israel_outstaff',
	'robots',
];

export const schetTaskCreateCalcSalaries: SectionConfig[] = makeDynamicTranslations([
	{
		label: tDynamic('Дополнительно'),
		fields: [
			{
				key: 'kwargs.today',
				label: tDynamic('Дата расчёта'),
				type: 'string',
				inputType: 'calendar',
				canEdit: true,
			},
			{
				key: 'kwargs.upload_to_yt',
				label: tDynamic('Загрузить результат в YT'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
			},
			{
				key: 'kwargs.upload_to_st',
				label: tDynamic('Загрузить результат в Startrek'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
			},
			{
				key: 'kwargs.save_to_db',
				label: tDynamic('Сохранить результат в базу данных'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
			},
			{
				key: 'kwargs.export_cpo',
				label: tDynamic('Выгрузить отчет CPO'),
				type: 'boolean',
				inputType: 'switch',
				canEdit: true,
			},
			{
				key: 'kwargs.calculations',
				label: tDynamic('Параметры расчётов'),
				type: 'object',
				inputType: 'fieldArray',
				dataTest: 'calculations',
				dataTestItem: 'calculation',
				addButtonText: tDynamic('Добавить условие'),
				direction: 'column',
				elementType: {
					label: '',
					fields: [
						{
							key: 'name',
							label: tDynamic('Имя калькулятора'),
							type: 'multiple-select',
							inputType: 'multiple-select',
							canEdit: true,
							options: salaryCalculationNames,
						},
						{
							label: 'start_delta',
							key: 'start_delta',
							type: 'string',
							inputType: 'JSON',
							canEdit: true,
						},
						{
							label: 'end_delta',
							key: 'end_delta',
							type: 'string',
							inputType: 'JSON',
							canEdit: true,
						},
					],
				},
			},
		],
	},
]);

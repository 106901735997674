import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ProductGroupsPage = lazy(() => import('../components/ProductGroupsPage'));
const ProductGroupsDataPage = lazy(() => import('../components/ProductGroupsPage/ProductGroupsDataPage'));

const productGroupsRoutes = makeRoutes([
	{
		path: '/product_groups',
		permitOneOf: ['group_product_groups_list_view', 'group_product_groups_create'],
		element: <ProductGroupsPage />,
	},
	{
		path: '/product_groups/add',
		permitOneOf: ['product_groups_create'],
		element: <ProductGroupsDataPage editMode addMode />,
	},
	{
		path: '/product_groups/edit/:group_id',
		permitOneOf: ['product_groups_save'],
		element: <ProductGroupsDataPage editMode />,
	},
	{
		path: '/product_groups/:group_id',
		permitOneOf: ['product_groups_load'],
		element: <ProductGroupsDataPage />,
	},
]);

export default productGroupsRoutes;

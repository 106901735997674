import type { ConfigProviderProps } from 'antd';
import dayjs from 'dayjs';
import type { ComponentType } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { logError } from '~shared/utils/logs';

import type { Language } from './types';
import { getMatchedLanguage } from './utils';

export function useDirection(ltrComponent: ComponentType, rtlComponent: ComponentType) {
	const [, i18n] = useTranslation();

	return useMemo(() => {
		const direction = i18n.dir();

		return direction === 'ltr' ? ltrComponent : rtlComponent;
	}, [i18n.language]);
}

export function useCountries() {
	const [, i18n] = useTranslation();
	const [isoList, setIsoList] = useState<Record<string, string>>({});
	const lang = i18n.language as Language;

	useAsyncEffect(async () => {
		if (!lang) return;
		const { isMatched, language } = getMatchedLanguage(lang);

		if (!isMatched) {
			logError({ lang }, new Error('Country data not found for language'));
		}

		const countries = await import(`./countries/${language}.json`);
		setIsoList(countries.default);
	}, [lang]);

	return isoList;
}

export function useLibTranslations() {
	const [, i18n] = useTranslation();
	const [antdLocale, setAntdLocale] = useState<ConfigProviderProps['locale'] | undefined>();
	const lang = i18n.language as Language;

	useAsyncEffect(async () => {
		if (!lang) return;

		const { isMatched, language } = getMatchedLanguage(lang);

		if (!isMatched) {
			logError({ lang }, new Error('Library translations not found for langhuage'));
		}
		const { translations } = await import(`./libs/${language}.ts`);

		dayjs.locale(translations.dayjs);
		setAntdLocale(translations.antd);
	}, [lang]);

	return { antdLocale };
}

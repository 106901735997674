/*global google*/
import type { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCouriersBriefByExternalId } from '~cache/useCouriersBriefByExternalId';
import GoogleMapInput from '~shared/components/Fields/GoogleMapField/MapInput';
import useGoogleMaps from '~shared/components/Fields/GoogleMapField/useGoogleMaps';
import type { UseLoadDataReturnType } from '~shared/hooks/useLoadData';
import { polygonOptionsGenerator } from '~shared/utils/mapFunctions';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';
import { useUserTheme } from '~zustand/userSettings';

import { generatePolygonColor } from '../../../StoresPage/StoresDataPage/GeoData/utils';
import { createPlacemarkBalloonBody, createPlacemarkBalloonHeader, getBallonOpenCallback } from '../Balloon';
import type { OrderWithCoords } from '../index';
import { useStyles } from '../styles';
import { getPreset, getShortNumber } from '../utils';
import createGoogleBalloon from './createGoogleBalloon';
import createGoogleInfoWindows from './createGoogleInfoWindows';
import createGoogleMarkerClusterer from './createGoogleMarkerClusterer';

export type GoogleInfoWindows = {
	add: (infoWindow: google.maps.InfoWindow) => void;
	closeAll: () => void;
};

interface Props {
	mapState: {
		center: number[];
		zoom?: number;
	};
	fetchZones: Omit<UseLoadDataReturnType, 'update'>;
	zone: Zones.Zone;
	orders: Record<string, OrderWithExtra>;
	editMode?: boolean;
	isLoading?: boolean;
	setUnassignCourierModalOrderId?: (orderId?: string) => void;
	setOrderModalOrderId?: (orderId?: string) => void;
}

export interface OrderWithExtra extends OrderWithCoords {
	isBatched?: boolean;
	batchedWith?: string[];
}

export type Refresher = () => void;
export type EdaStatusColor = 'gray' | 'blue';

export type GoogleBalloon = google.maps.OverlayView &
	google.maps.Marker & {
		docNumber: string;
		orderId: string;
		color: EdaStatusColor;
		infoWindowContent: string;
		setClusterInfoWindowRefresher: (refresher: Refresher) => void;
		setTitle: (title: string) => void;
		setColor: (color: EdaStatusColor) => void;
		setInfoWindowContent: (content: string) => void;
		setTheme: (isLight: boolean) => void;
		forceRerender: () => void;
	};

const GoogleOrdersMap = ({
	mapState,
	fetchZones,
	zone,
	orders,
	editMode,
	isLoading,
	setUnassignCourierModalOrderId,
	setOrderModalOrderId,
}: Props) => {
	const { classes } = useStyles();
	const ezonesValue = [zone];
	const ready: boolean = useGoogleMaps();
	const map = useRef<google.maps.Map | null>(null);
	const clusterer = useRef<MarkerClusterer | null>(null);
	const ordersOnMap = useRef<Record<string, GoogleBalloon>>({});
	const infoWindows = useRef<GoogleInfoWindows>(createGoogleInfoWindows());
	const [isFullScreen, setFullScreen] = useState<boolean>(false);

	const couriersBriefByExternalId = useCouriersBriefByExternalId(
		Object.values(orders).map((order) => order.courier?.taxi_driver_uuid)
	);
	const location = useLocation();

	const theme = useUserTheme();
	const isLight = theme === 'light';

	// передаем карту из базового компонента, чтобы ее модифицировать в этой обертке
	const setMapRef = (mapRef: any) => (map.current = mapRef);

	useEffect(() => {
		if (!ready || !map.current) {
			return;
		}

		Object.values(orders).forEach((order) => {
			const [lng, lat] = order.coordinates ?? [];
			if (typeof lng === 'number' && typeof lat === 'number') {
				let balloon: any;

				if (order.order_id in ordersOnMap.current) {
					balloon = ordersOnMap.current[order.order_id];
				} else {
					const getEventListeners = (orderId: string) =>
						getBallonOpenCallback({
							orderId,
							setUnassignCourierModalOrderId,
							setOrderModalOrderId,
						});
					balloon = createGoogleBalloon({
						map: map.current!,
						infoWindows: infoWindows.current,
						coords: {
							lng,
							lat,
						},
						classes: {
							markContainerClass: classes.markContainer,
							markClass: classes.mark,
						},
						docNumber: order.doc_number,
						orderId: order.order_id,
						getEventListeners,
					});

					if (!clusterer.current) {
						clusterer.current = createGoogleMarkerClusterer({
							map: map.current,
							infoWindows: infoWindows.current,
							classes: {
								containerClasses: [classes.clusterInfoWindowContainer],
								leftClasses: [classes.clusterInfoWindowLeft, classes.thinScroll],
								rightClasses: [classes.clusterInfoWindowRight, classes.thinScroll],
							},
							getEventListeners,
						});
					}

					clusterer.current.addMarker(balloon);
					ordersOnMap.current[order.order_id] = balloon;
				}

				const balloonHeader = createPlacemarkBalloonHeader({
					orderId: order.order_id,
					docNumber: `${order.isBatched ? '🔗 ' : ''}${order.doc_number}`,
				});

				const balloonBody = createPlacemarkBalloonBody({
					order,
					courier: order.courier?.taxi_driver_uuid
						? couriersBriefByExternalId[order.courier.taxi_driver_uuid]
						: undefined,
				});

				balloon.setTitle(`${order.isBatched ? '🔗 ' : ''}${getShortNumber(order.doc_number)}`);
				balloon.setColor(getPreset('google', order.eda_status));
				balloon.setInfoWindowContent(balloonHeader + balloonBody);
				balloon.setTheme(isLight);
			}
		});

		Object.keys(ordersOnMap.current).forEach((orderId) => {
			if (!(orderId in orders)) {
				clusterer.current?.removeMarker(ordersOnMap.current[orderId]);
				delete ordersOnMap.current[orderId];
			}
		});
	}, [ready, orders]);

	useEffect(() => {
		Object.values(ordersOnMap.current).forEach((balloon) => {
			balloon.setTheme(isLight);
		});
	}, [isLight]);

	if (isLoading) {
		return <Spin />;
	}

	const renderMap = () => {
		const polygons = ezonesValue
			? ezonesValue.map((eZone, index) => ({
					items: eZone.zone,
					options: {
						...polygonOptionsGenerator(
							zone.status as Stores.Store['status'],
							{
								delivery_type: eZone.delivery_type,
								status: eZone.status,
								color: generatePolygonColor(eZone.delivery_type, zone.status as Stores.Store['status'], index),
							},
							editMode,
							isFullScreen
						),
					},
				}))
			: [];

		const mapProps = {
			setMapRef,
			initPlacemarkDeps: [location, fetchZones.loaded, editMode, isFullScreen],
			initPolygonsDeps: [0, fetchZones.loaded, isFullScreen],
			fullScreenCallback: setFullScreen,
			polygons: [polygons[0]],
		};

		return (
			<GoogleMapInput
				{...mapProps}
				placemark={{
					item: {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: mapState.center,
						},
					},
					options: { zoom: mapState.zoom },
				}}
				isReady={ready}
			/>
		);
	};

	return <div>{renderMap()}</div>;
};

export default GoogleOrdersMap;

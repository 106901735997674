import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';

import type { SearchData } from '../index';
import { filterOrders, getCourierName } from '../index';
import { formatPlannedTime } from './plannedTime';

export const filterOrdersForTable = (
	orders: Orders.Order[],
	searchData: SearchData,
	couriers: Record<Couriers.CourierExternalId, Couriers.CourierBrief | undefined>
) => {
	const firstFilterIteration = filterOrders(orders);

	if (!searchData) {
		return firstFilterIteration;
	}

	const isPlannedTimeValid = (order: Orders.Order) =>
		searchData.planned_time?.includes(formatPlannedTime(order)) ?? true;

	const isOrderStatusValid = (order: Orders.Order) => searchData.order_status?.includes(order.status) ?? true;

	const isEdaStatusValid = (order: Orders.Order) => searchData.eda_status?.includes(order.eda_status) ?? true;

	const isCourierIdValid = (order: Orders.Order) =>
		searchData.courier_name?.includes(getCourierName(order, couriers) ?? 'Not assigned') ?? true;

	const isPayMethodValid = (order: Orders.Order) =>
		searchData.pay_method?.includes(order.attr?.invoices?.[0]?.invoice_payment_method_type ?? '') ?? true;

	return firstFilterIteration.filter(
		(order) =>
			isPlannedTimeValid(order) &&
			isOrderStatusValid(order) &&
			isEdaStatusValid(order) &&
			isCourierIdValid(order) &&
			isPayMethodValid(order)
	);
};

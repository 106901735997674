import { useMemo } from 'react';

import i18n from '~/i18n';
import analyticStatsInfo from '~constants/analyticStatsInfo';
import capitalize from '~shared/utils/capitalize';
import getIsMetricInRed from '~shared/utils/getIsMetricInRed';
import getProperTime from '~shared/utils/transformSecondsToTimeString';

import type { HealthLevelMetrics, StatDetails } from '../../healthTypes';
import type { ThresholdDataEntry } from '../../hooks/useGetThresholds';
import StatBlock from '../StatBlock';
import { useStyles } from './styles';

type Props = {
	statsData: {
		now: Partial<HealthLevelMetrics>;
		past: Partial<HealthLevelMetrics>;
	};
	thresholds: ThresholdDataEntry;
};

const statKeys = [
	'full_cte',
	'assemble_wait_time',
	'assemble_time',
	'waiting_for_pickup',
	'pickup_time',
	'to_client_time',
	'orders_count',
	'store_surge_level',
	'stowage_speed_time',
] as const;

const getStatsFromData = (statsData: Props['statsData'], thresholds?: ThresholdDataEntry): StatDetails[] => {
	return statKeys.map((key) => {
		let val: number | string = statsData.now[key] ?? 0;
		const rawDiff = (statsData.now[key] ?? 0) - (statsData.past[key] ?? 0);
		let diff: number | string = rawDiff;

		const threshold = thresholds && key in thresholds ? thresholds[key as keyof ThresholdDataEntry] : undefined;
		let thresholdValue: number | string | undefined = threshold;

		if (analyticStatsInfo[key].type === 'time') {
			val = getProperTime(Math.floor(val));
			diff = getProperTime(Math.floor(diff), true);
			thresholdValue = thresholdValue && getProperTime(Math.floor(thresholdValue), true);
		}

		if (val && key === 'orders_count') {
			val = i18n.t('{{value}} шт.', { value: val });
			diff = i18n.t('{{value}} шт.', { value: diff }) ?? '—';
		}

		if (val && key === 'store_surge_level') {
			val = i18n.t('{{value}} складов', { value: val });
			diff = i18n.t('{{value}} складов', { value: diff }) ?? '—';
		}

		return {
			key,
			title: capitalize(analyticStatsInfo[key].label),
			value: val ? String(val) : '—',
			diff: val && diff ? String(diff) : '—',
			sign: rawDiff ? rawDiff > 0 : null,
			threshold: val && thresholdValue ? thresholdValue : undefined,
			isRed: threshold ? getIsMetricInRed(key, statsData.now[key], threshold) : undefined,
		};
	});
};

const Stats = ({ statsData, thresholds }: Props) => {
	const { classes } = useStyles();
	const stats = useMemo(() => getStatsFromData(statsData, thresholds), [statsData, thresholds]);

	return (
		<div className={classes.statsBlock}>
			{stats.map((stat) => (
				<StatBlock
					key={stat.key}
					stat={stat}
					threshold={
						thresholds && stat.key in thresholds ? thresholds[stat.key as keyof ThresholdDataEntry] : undefined
					}
				/>
			))}
		</div>
	);
};

export default Stats;

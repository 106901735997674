import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const UsersLogPage = lazy(() => import('../components/UsersLogPage'));

const usersLogRoutes = makeRoutes([
	{
		path: '/users_log/',
		element: <UsersLogPage />,
	},
]);

export default usersLogRoutes;

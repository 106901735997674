import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import i18n from '~/i18n';
import { useCache } from '~cache/useCache';
import { mobileMaxWidth } from '~constants/breakPoints';
import EventsCalendar from '~shared/components/EventsCalendar';
import NotificationBtn from '~shared/components/Notifications/NotificationBtn';
import { Link } from '~shared/ui-kit/Link';
import cutLongText from '~shared/utils/cutLongText';
import { getRoleNames } from '~shared/utils/getRoleName';
import type { User } from '~types/user';
import { useCheckPermit, useUser } from '~zustand/userData';

import Settings from '../Settings';
import { useStyles } from './styles';

export const HeaderRight = () => {
	const [t] = useTranslation();
	const user = useUser();
	const isPermitChat = useCheckPermit('chat');
	const isPermitActiveProjects = useCheckPermit('active_projects');

	const { classes } = useStyles();

	const { stores, roles } = useCache({
		stores: user.store_id,
		roles: user.role_ids,
	});

	const isMobile = useMediaQuery({
		query: `(max-width: ${mobileMaxWidth}px)`,
	});

	const roleNamesText = (data: User.RoleIdsType) => {
		const userRoleNames = data
			.map((role) => getRoleNames(roles[role])?.[role])
			.filter((name): name is string => typeof name === 'string');

		const formatter = new Intl.ListFormat(i18n.language.replace('_', '-'), { style: 'short', type: 'conjunction' });
		return formatter.format(userRoleNames);
	};

	return (
		<div className={classes.container}>
			{!isMobile && (
				<div className={classes.userInfo}>
					<div className={classes.userRole} data-test="layout user role">
						{user.role ? cutLongText(roleNamesText(user.role_ids), 30) : ''}
					</div>
					<div data-test="layout user store">
						{user.store_id && stores[user.store_id]?.title ? (
							<Link to={`/stores/${user.store_id}`} className={classes.userStore}>
								{cutLongText(stores[user.store_id]?.title ?? '', 20)}
								{' >'}
							</Link>
						) : (
							<div className={classes.userStore}>{t('Не привязан к складу')}</div>
						)}
					</div>
				</div>
			)}

			{user.store_id && isPermitChat && <NotificationBtn inHeader />}
			{isPermitActiveProjects && <EventsCalendar />}
			<Settings />
		</div>
	);
};

import { Checkbox } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { productsFields } from '~constants/dataPageFields/productPageFields';
import type { SectionConfig } from '~constants/dataPageFields/types';
import emptyStateImage from '~images/empty-state-image.svg';
import type { Products } from '~types/products';

import Gallery from '../DataPageImages/Gallery';
import { RegradingContext } from './context';
import { ProductField } from './ProductField';
import { useStyles } from './styles';
import type { RegradingProducts, RegradingProductsType } from './types';
import { checkIfProductsNotEquals, getProductsWithMasterGroups } from './utils';

export const CompareProductsData = () => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { products } = useContext(RegradingContext);
	const [onlyDiffs, setOnlyDiffs] = useState<boolean>(false);
	const mainConfigSection = productsFields.find((section) => section.label === t('Основное'));
	const nutritionalConfigSection = productsFields.find(
		(section) => section.label === t('Пищевая (Энергетическая) ценность в 100 г')
	);
	const [currentProductsFields, setCurrentProductsFields] = useState<SectionConfig['fields'] | undefined>(
		mainConfigSection?.fields
	);
	const [nutritionalProductsFields, setNutritionalProductsFields] = useState<SectionConfig['fields'] | undefined>(
		nutritionalConfigSection?.fields
	);

	const cache = useCache({
		productGroups: [],
	});

	const filteredProducts = getProductsWithMasterGroups(products, cache.productGroups);

	const showOnlyDifferences = (products: RegradingProducts<Products.Product | null | undefined>) => {
		if (!products || !products.old || !products.regrade) {
			return;
		}

		const filtered = mainConfigSection?.fields.filter((field) =>
			checkIfProductsNotEquals(products, field.key as keyof Products.Product)
		);
		const nutritionalFiltered = nutritionalConfigSection?.fields.filter((field) =>
			checkIfProductsNotEquals(products, field.key as keyof Products.Product)
		);
		setOnlyDiffs(!onlyDiffs);

		setCurrentProductsFields(onlyDiffs ? mainConfigSection?.fields : filtered);
		setNutritionalProductsFields(onlyDiffs ? nutritionalConfigSection?.fields : nutritionalFiltered);
	};

	// Для подсветки неправильно заполненных полей, необходимых для успешного создания рекласса
	const problemFields = useMemo(() => {
		const fields: string[] = [];
		if (checkIfProductsNotEquals(filteredProducts, 'groups')) {
			fields.push('groups');
		}

		if (checkIfProductsNotEquals(products, 'products_scope')) {
			fields.push('products_scope');
		}

		if (products.old && products.regrade && products.old['status'] !== 'archive') {
			fields.push('status');
		}

		return fields;
	}, [products.old, products.regrade]);

	useCache({
		products: [
			products.old?.product_id,
			products.regrade?.product_id,
			products.old?.parent_id,
			products.regrade?.parent_id,
		],
	});

	if (!products.old || !products.regrade) {
		return null;
	}

	return (
		<section>
			<Checkbox
				onChange={() => showOnlyDifferences(filteredProducts)}
				checked={onlyDiffs}
				className={classes.checkbox}
				data-test="show only diffs regrading checkbox"
			>
				{t('Показать только различия')}
			</Checkbox>
			<section className={classes.compareSection}>
				<span className={classes.sectionLabel}>{mainConfigSection?.label}</span>
				<>
					{currentProductsFields
						?.filter((el) => !['product_id', 'images'].includes(el.key))
						.map((el) => (
							<div
								key={el.key}
								className={cx(classes.fieldContainer, { problem: problemFields.includes(el.key) })}
								data-test={`product-field-${el.label}`}
							>
								<span className={classes.fieldLabel}>{el.label}</span>
								<div className={classes.fieldValuesContainer}>
									{Object.keys(products).map((productKey) => (
										<ProductField
											fieldKey={el.key}
											productKey={productKey as RegradingProductsType}
											key={`${productKey}-${el.key}`}
										/>
									))}
								</div>
							</div>
						))}
				</>
			</section>
			<section className={classes.compareSection}>
				<span className={classes.sectionLabel}>{nutritionalConfigSection?.label}</span>
				{nutritionalProductsFields
					?.filter((el) => !el.hidden)
					.map((el) => (
						<div key={el.key} className={classes.fieldContainer}>
							<span className={classes.fieldLabel}>{el.label}</span>
							<div className={classes.fieldValuesContainer}>
								{Object.keys(products).map((productKey) => (
									<ProductField
										fieldKey={el.key}
										productKey={productKey as RegradingProductsType}
										key={`${productKey}-${el.key}`}
									/>
								))}
							</div>
						</div>
					))}
			</section>
			<section className={classes.compareSection}>
				<span className={classes.sectionLabel}>{t('Фото')}</span>
				<div className={classes.fieldValuesContainer}>
					{Object.values(products).map((product, index) =>
						product?.images?.length ? (
							<Gallery images={product.images} key={product.product_id} />
						) : (
							<img src={emptyStateImage} key={product?.product_id || index} />
						)
					)}
				</div>
			</section>
		</section>
	);
};

import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import api from '~/api';
import { mobileMaxWidth, tabletMaxWidth } from '~constants/breakPoints';
import type { CheckProjectStatuses } from '~server-types/doc/api/models/check_project';
import {
	checkProjectStatusesNames,
	checkProjectStatusesValues,
	checkProjectTypesNames,
} from '~server-types/doc/api/models/check_project';
import useStoresInfo from '~shared/components/Fields/LocationTreeField/useStoresInfo';
import TableHeader from '~shared/components/TableHeader';
import TablePagination from '~shared/components/TablePagination';
import useLoadData from '~shared/hooks/useLoadData';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import { Link } from '~shared/ui-kit/Link';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import { ProTableToolbar } from '~shared/ui-kit/ProTable/ProTableToolbar';
import { PlusOutlined } from '~shared/utils/icons';
import renderStatus from '~shared/utils/renderStatus';
import type { CheckProjects } from '~types/checkProjects';
import { useCheckPermit, useCheckPermitGroup, useUser } from '~zustand/userData';

import CheckProjectActionModal from './CheckProjectActionModal';
import CheckProjectModal from './CheckProjectModal';
import Filter from './Filter';
import { checkProjectStatusColors, statusOrder } from './pageConfig';
import { useStyles } from './styles';
import TableActions from './TableActions';
import IntervalBlock from './TableBlocks/IntervalBlock';
import LocationBlock from './TableBlocks/LocationBlock';
import NextPlannedBlock, { getNextCheckTimeFromSchedule } from './TableBlocks/NextPlannedBlock';
import PeriodBlock from './TableBlocks/PeriodBlock';
import ProductsBlock from './TableBlocks/ProductsBlock';
import { isApprovable, isProjectComplete } from './utils/helpers';

type RowType = CheckProjects.CheckProject & { paused: number | null };

const CheckProjectsPage = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	const isTablet = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});

	const isMobile = useMediaQuery({
		query: `(max-width: ${mobileMaxWidth}px)`,
	});

	const title = t('Проекты контроля');
	useSetTitle(title);

	const isPermitCheckProjectsSave = useCheckPermit('check_projects_save');
	const isPermitCheckProjectsLoad = useCheckPermit('check_projects_load');

	const isPermitGroupCheckProjectView = useCheckPermitGroup('group_check_project_view');

	const [modalOpen, toggleModalOpen] = useState<string | null>(null);
	const [projectToShowId, setProjectToShowId] = useState<string | undefined>(undefined);
	const [projectModifiedId, setProjectModifiedId] = useState<string | null>(null);
	const [loading, toggleLoading] = useState<boolean>(false);

	const [cursor, setCursor] = useState<string>('');
	const [searchData, setSearchData] = useState<{ status: CheckProjectStatuses[] }>({
		status: ['active', 'draft', 'waiting_approve'],
	});

	const searchCheckProjects = (values: typeof searchData) => {
		setSearchData(values);
		setCursor('');
	};

	const user = useUser();
	const { getTotalPausedCount, storesLoading, allStoresByCluster } = useStoresInfo({
		companyId: user.company_id,
		pauseCondition: (store) => store.status !== 'active' || !store.options.exp_big_brother,
	});

	const {
		data: checkProjectsData,
		loading: isLoading,
		req,
	} = useLoadData(
		() => api.checkProjects.list({ cursor, ...searchData }),
		[cursor, searchData],
		!isPermitCheckProjectsLoad
	);

	const checkProjects = useMemo(
		() =>
			checkProjectsData?.results.map(
				(project) =>
					({
						...project,
						paused: storesLoading
							? null
							: getTotalPausedCount({
									storeIds: project.stores.store_id!,
									clusterIds: project.stores.cluster_id!,
								}),
					}) as RowType
			),
		[storesLoading, checkProjectsData, allStoresByCluster]
	);

	const columns: ProColumns<RowType>[] = [
		{
			title: t('Тип проекта'),
			dataIndex: 'check_project_type',
			key: 'check_project_type',
			render: (text) => checkProjectTypesNames[text],
			sorter: (a, b) =>
				checkProjectTypesNames[a.check_project_type].localeCompare(checkProjectTypesNames[b.check_project_type]),
		},
		{
			title: t('Название проекта'),
			dataIndex: 'title',
			key: 'title',
			render: (text, data) =>
				text ? (
					<Link
						to={`/check_projects/${data.check_project_id}?tab=main`}
						className={classes.titleCell}
						data-test={`check project view link ${text}`}
						onLeftClick={() => setProjectToShowId(data.check_project_id)}
					>
						{text}
					</Link>
				) : (
					'—'
				),
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: t('Статус'),
			dataIndex: 'status',
			key: 'status',
			render: (text) => renderStatus(text, checkProjectStatusesNames, checkProjectStatusColors),
			sorter: (a, b) => statusOrder[a.status] - statusOrder[b.status],
		},
		{
			title: t('Локация'),
			dataIndex: 'stores',
			key: 'stores',
			render: (stores, data) => <LocationBlock stores={stores} paused={data.paused} />,
		},
		{
			title: t('Товары и категории'),
			dataIndex: 'products',
			key: 'products',
			render: (products) => <ProductsBlock products={products} />,
		},
		{
			title: t('Повтор'),
			dataIndex: 'schedule',
			key: 'schedule_timetable',
			width: 150,
			render: (schedule) => <IntervalBlock timetable={schedule.timetable} />,
		},
		{
			title: t('Период'),
			dataIndex: 'schedule',
			key: 'schedule_period',
			render: (schedule) => <PeriodBlock begin={schedule.begin} end={schedule.end} />,
		},
		{
			title: t('Следующая инвентаризация'),
			key: 'next_check',
			render: (_, data) => <NextPlannedBlock schedule={data.schedule} />,
			sorter: (a, b) => {
				const nextA = getNextCheckTimeFromSchedule(a.schedule);
				const nextB = getNextCheckTimeFromSchedule(b.schedule);
				return nextA && nextB ? (dayjs(nextA).isBefore(dayjs(nextB)) ? 1 : -1) : nextA ? 1 : -1;
			},
		},
		{
			title: '',
			key: 'actions',
			fixed: 'right',
			render: (_, project) => (
				<TableActions
					status={project.status}
					openModal={(type) => toggleModalOpen(type)}
					setProjectModifiedId={setProjectModifiedId}
					id={project.check_project_id}
					options={{
						isFormComplete: isProjectComplete(project),
						isApprovable: isApprovable(user.user_id, project.vars),
					}}
				/>
			),
		},
	];

	return (
		<div data-test="check_projects page">
			<CheckProjectActionModal
				modalOpen={modalOpen}
				toggleModalOpen={toggleModalOpen}
				loading={loading || isLoading}
				toggleLoading={toggleLoading}
				projectModifiedId={projectModifiedId}
				setProjectModifiedId={setProjectModifiedId}
				checkProject={checkProjectsData?.results.find((e) => e.check_project_id === projectModifiedId)}
				updateCheckProject={req}
			/>
			{projectToShowId && (
				<CheckProjectModal
					projectToShow={checkProjectsData?.results.find((e) => e.check_project_id === projectToShowId)}
					setProjectToShowId={setProjectToShowId}
					toggleModalOpen={toggleModalOpen}
					setProjectModifiedId={setProjectModifiedId}
					userId={user.user_id}
				/>
			)}
			<TableHeader
				title={title}
				primaryButtons={[
					{
						type: 'link',
						to: '/check_projects/add',
						dataTest: 'check_projects toolbar add button',
						text: t('Добавить'),
						hideText: isTablet || isMobile,
						icon: isTablet || isMobile ? <PlusOutlined /> : undefined,
						condition: isPermitCheckProjectsSave,
					},
				]}
			/>
			<ProTableToolbar>
				<TablePagination
					key={1}
					itemsAmount={checkProjectsData?.results.length}
					loadPage={setCursor}
					currentCursor={cursor}
					loading={loading || isLoading}
				/>
				<Filter
					key="statuses"
					allValues={checkProjectStatusesValues}
					valuesToShow={searchData.status}
					onChange={(status) => searchCheckProjects({ status })}
					dictionary={checkProjectStatusesNames}
					dataTest="check_project table status filter"
				/>
			</ProTableToolbar>
			<ProTable
				rowKey="check_project_id"
				tableLayout="auto"
				columns={columns}
				dataSource={checkProjects}
				loading={loading || isLoading}
				data-test="check_projects table"
				showNoAccessIfNoData={isPermitGroupCheckProjectView}
			/>
		</div>
	);
};

export default CheckProjectsPage;

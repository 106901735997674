import { makeRoutes } from '~shared/utils/makeRoutes';

import CheckProjects from '../components/CheckProjects';
import CheckProjectsDataPage from '../components/CheckProjects/CheckProjectsDataPage';

const checkProjectsRoutes = makeRoutes([
	{
		path: '/check_projects',
		permitOneOf: ['check_projects_load', 'check_projects_save'],
		element: <CheckProjects />,
	},
	{
		path: '/check_projects/add',
		permitOneOf: ['check_projects_save'],
		element: <CheckProjectsDataPage editMode addMode />,
	},
	{
		path: '/check_projects/edit/:check_project_id',
		permitOneOf: ['check_projects_save'],
		element: <CheckProjectsDataPage editMode />,
	},
	{
		path: '/check_projects/:check_project_id',
		permitOneOf: ['check_projects_load'],
		element: <CheckProjectsDataPage />,
	},
]);

export default checkProjectsRoutes;

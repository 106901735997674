// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type SchetTaskStatus = 'paused' | 'pending' | 'delayed' | 'running' | 'deleted';
export const schetTaskStatusValues = ['paused', 'pending', 'delayed', 'running', 'deleted'] as SchetTaskStatus[];
export const schetTaskStatusNames = makeServerTranslations(schetTaskStatusValues, 'enums.SCHET_TASK_STATUS');

export type SchetTaskScope = 'store' | 'company';
export const schetTaskScopeValues = ['store', 'company'] as SchetTaskScope[];
export const schetTaskScopeNames = makeServerTranslations(schetTaskScopeValues);

export type SchetTaskCreateCronsHealthcheck = 'crons_healthcheck';
export const schetTaskCreateCronsHealthcheckValues = ['crons_healthcheck'] as SchetTaskCreateCronsHealthcheck[];
export const schetTaskCreateCronsHealthcheckNames = makeServerTranslations(schetTaskCreateCronsHealthcheckValues);

export type SchetTaskCreateCreateCheckValid = 'create_check_valid';
export const schetTaskCreateCreateCheckValidValues = ['create_check_valid'] as SchetTaskCreateCreateCheckValid[];
export const schetTaskCreateCreateCheckValidNames = makeServerTranslations(schetTaskCreateCreateCheckValidValues);

export type SchetTaskCreateCreateCheckValidOrderType =
	| 'check_valid_short'
	| 'check_valid_regular'
	| 'writeoff_prepare_day'
	| 'visual_control';
export const schetTaskCreateCreateCheckValidOrderTypeValues = [
	'check_valid_short',
	'check_valid_regular',
	'writeoff_prepare_day',
	'visual_control',
] as SchetTaskCreateCreateCheckValidOrderType[];
export const schetTaskCreateCreateCheckValidOrderTypeNames = makeServerTranslations(
	schetTaskCreateCreateCheckValidOrderTypeValues
);

export type OrderCheckValidMode =
	| 'store2trash'
	| 'store2markdown'
	| 'eatToday2markdown'
	| 'markdown2trash'
	| 'store2review'
	| 'review2trash';
export const orderCheckValidModeValues = [
	'store2trash',
	'store2markdown',
	'eatToday2markdown',
	'markdown2trash',
	'store2review',
	'review2trash',
] as OrderCheckValidMode[];
export const orderCheckValidModeNames = makeServerTranslations(
	orderCheckValidModeValues,
	'enums.ORDER_CHECK_VALID_MODE'
);

export type SchetTaskCreateCreateWriteoff = 'create_writeoff';
export const schetTaskCreateCreateWriteoffValues = ['create_writeoff'] as SchetTaskCreateCreateWriteoff[];
export const schetTaskCreateCreateWriteoffNames = makeServerTranslations(schetTaskCreateCreateWriteoffValues);

export type SchetTaskCreateCreateWriteoffOrderType = 'writeoff';
export const schetTaskCreateCreateWriteoffOrderTypeValues = ['writeoff'] as SchetTaskCreateCreateWriteoffOrderType[];
export const schetTaskCreateCreateWriteoffOrderTypeNames = makeServerTranslations(
	schetTaskCreateCreateWriteoffOrderTypeValues
);

export type SchetTaskCreateSyncProducts = 'sync_products';
export const schetTaskCreateSyncProductsValues = ['sync_products'] as SchetTaskCreateSyncProducts[];
export const schetTaskCreateSyncProductsNames = makeServerTranslations(schetTaskCreateSyncProductsValues);

export type SchetTaskCreateSyncProductsBirdName = 'pigeon' | 'pigeon_chef' | 'pigeon_nana' | 'pigeon_nana_aws';
export const schetTaskCreateSyncProductsBirdNameValues = [
	'pigeon',
	'pigeon_chef',
	'pigeon_nana',
	'pigeon_nana_aws',
] as SchetTaskCreateSyncProductsBirdName[];
export const schetTaskCreateSyncProductsBirdNameNames = makeServerTranslations(
	schetTaskCreateSyncProductsBirdNameValues
);

export type SchetTaskCreateReplenishKitchenAssortment = 'replenish_kitchen_assortment';
export const schetTaskCreateReplenishKitchenAssortmentValues = [
	'replenish_kitchen_assortment',
] as SchetTaskCreateReplenishKitchenAssortment[];
export const schetTaskCreateReplenishKitchenAssortmentNames = makeServerTranslations(
	schetTaskCreateReplenishKitchenAssortmentValues
);

export type SchetTaskCreateRobotReplenishment = 'robot_replenishment';
export const schetTaskCreateRobotReplenishmentValues = ['robot_replenishment'] as SchetTaskCreateRobotReplenishment[];
export const schetTaskCreateRobotReplenishmentNames = makeServerTranslations(schetTaskCreateRobotReplenishmentValues);

export type SchetTaskCreateUpdateTrueMarkToken = 'update_true_mark_token';
export const schetTaskCreateUpdateTrueMarkTokenValues = [
	'update_true_mark_token',
] as SchetTaskCreateUpdateTrueMarkToken[];
export const schetTaskCreateUpdateTrueMarkTokenNames = makeServerTranslations(schetTaskCreateUpdateTrueMarkTokenValues);

export type SchetTaskCreateUpdateVat = 'update_vat';
export const schetTaskCreateUpdateVatValues = ['update_vat'] as SchetTaskCreateUpdateVat[];
export const schetTaskCreateUpdateVatNames = makeServerTranslations(schetTaskCreateUpdateVatValues);

export type SchetTaskCreateSyncPriceLists = 'sync_price_lists';
export const schetTaskCreateSyncPriceListsValues = ['sync_price_lists'] as SchetTaskCreateSyncPriceLists[];
export const schetTaskCreateSyncPriceListsNames = makeServerTranslations(schetTaskCreateSyncPriceListsValues);

export type RelativeDeltaWeekdayDay = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';
export const relativeDeltaWeekdayDayValues = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'] as RelativeDeltaWeekdayDay[];
export const relativeDeltaWeekdayDayNames = makeServerTranslations(relativeDeltaWeekdayDayValues);

export type SchetTaskCreateCalcSalaries = 'calc_salaries';
export const schetTaskCreateCalcSalariesValues = ['calc_salaries'] as SchetTaskCreateCalcSalaries[];
export const schetTaskCreateCalcSalariesNames = makeServerTranslations(schetTaskCreateCalcSalariesValues);

export type SchetTaskCreateCalcSalariesCalculationsName =
	| 'russia_main'
	| 'russia_region'
	| 'israel_selfemployed'
	| 'israel_neto'
	| 'israel_outstaff'
	| 'robots';
export const schetTaskCreateCalcSalariesCalculationsNameValues = [
	'russia_main',
	'russia_region',
	'israel_selfemployed',
	'israel_neto',
	'israel_outstaff',
	'robots',
] as SchetTaskCreateCalcSalariesCalculationsName[];
export const schetTaskCreateCalcSalariesCalculationsNameNames = makeServerTranslations(
	schetTaskCreateCalcSalariesCalculationsNameValues
);

import { makeRoutes } from '~shared/utils/makeRoutes';

/** Внутри этих компонентов по конфигу рендерятся lazy-компоненты */
import { WalletCourierPage, WalletStorePage } from '../components/WalletFinancePage';

const wallet = makeRoutes([
	{
		path: '/wallet',
		permitOneOf: ['finance_wallet_access'],
		exp: 'exp_lebowski',
		ensureStoreIdExists: true,
		element: <WalletStorePage />,
	},
	{
		path: '/wallet/couriers/:courier_id',
		permitOneOf: ['finance_wallet_access'],
		exp: 'exp_lebowski',
		ensureStoreIdExists: true,
		element: <WalletCourierPage />,
	},
]);

export default wallet;

// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type UserStatuses = 'active' | 'disabled';
export const userStatusesValues = ['active', 'disabled'] as UserStatuses[];
export const userStatusesNames = makeServerTranslations(userStatusesValues, 'enums.USER_STATUSES');

export type UserProviders = 'guest' | 'yandex' | 'yandex-team' | 'auth-api' | 'test' | 'test_fake' | 'internal';
export const userProvidersValues = [
	'guest',
	'yandex',
	'yandex-team',
	'auth-api',
	'test',
	'test_fake',
	'internal',
] as UserProviders[];
export const userProvidersNames = makeServerTranslations(userProvidersValues, 'enums.USER_PROVIDERS');

export type UserSessionSource = 'tsd' | 'admin' | 'lavkach';
export const userSessionSourceValues = ['tsd', 'admin', 'lavkach'] as UserSessionSource[];
export const userSessionSourceNames = makeServerTranslations(userSessionSourceValues, 'enums.USER_SESSION_SOURCE');

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import TreeView from '~shared/components/Fields/TreeSelectField/TreeView';
import type { RegradingProductsType } from '~shared/components/Regrading/types';
import type { Products } from '~types/products';

import { RegradingContext } from './context';
import { useStyles } from './styles';
import { getImportedValues, getProductFieldValue, priceTypes } from './utils';

interface Props {
	fieldKey: string;
	productKey: RegradingProductsType;
}

export const ProductField = ({ fieldKey, productKey }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { products, inAssortments, price } = useContext(RegradingContext);
	const product = products[productKey] as Products.Product;
	const imported = getImportedValues(product.vars?.imported);

	const cache = useCache({
		products: [],
	});

	let fieldValue = getProductFieldValue(product, fieldKey, productKey, cache.products, inAssortments);

	if (
		['sizes', 'private_label', 'is_meta', 'calorie', 'fat', 'carbohydrate', 'protein', 'warehouse_group_list'].includes(
			fieldKey
		)
	) {
		const value = imported?.[fieldKey as keyof Products.Imported];

		if ((Array.isArray(value) || typeof value === 'string') && value.length) {
			fieldValue = value;
		} else {
			fieldValue = undefined;
		}
	}

	if (
		[
			'vars.imported.leftover_for_visual_control',
			'vars.imported.netto_weight',
			'vars.imported.brutto_weight',
			'vars.imported.true_mark',
			'vars.imported.nomenclature_type',
			'vars.imported.logistic_tags',
		].includes(fieldKey)
	) {
		const currentKey = fieldKey.split('.').pop()! as keyof Pick<
			Products.Imported,
			| 'leftover_for_visual_control'
			| 'netto_weight'
			| 'brutto_weight'
			| 'true_mark'
			| 'nomenclature_type'
			| 'logistic_tags'
		>;

		const key = product.vars?.imported?.[currentKey];
		fieldValue = key;

		if (currentKey === 'true_mark') {
			fieldValue = fieldValue ? t('Да') : t('Нет');
		}
	}

	if (fieldKey === 'groups') {
		return (
			<TreeView
				key={fieldKey}
				path="product_groups"
				showAllParents={true}
				value={product[fieldKey] ?? []}
				showFrontCategory={false}
			/>
		);
	}

	if (fieldKey === 'price') {
		return (
			<p>
				{Object.keys(priceTypes).map((key) => (
					<div className={classes.fieldContainer} key={key}>
						<div
							className={cx(classes.fieldLabel, {
								transparent: productKey === 'regrade',
							})}
						>
							{priceTypes[key as keyof typeof priceTypes]}
						</div>
						<div className={classes.fieldValuesContainer}>
							{price[productKey]?.[key as keyof typeof priceTypes] ?? (
								<span className={classes.noValue}>{t('Не заполнено')}</span>
							)}
						</div>
					</div>
				))}
			</p>
		);
	}

	return <p key={fieldKey}>{fieldValue ?? <span className={classes.noValue}>{t('Не заполнено')}</span>}</p>;
};

// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type StorePrintSetupStickerSize = '50x150';
export const storePrintSetupStickerSizeValues = ['50x150'] as StorePrintSetupStickerSize[];
export const storePrintSetupStickerSizeNames = makeServerTranslations(storePrintSetupStickerSizeValues);

export type StickerLogo = 'lavka' | 'deli';
export const stickerLogoValues = ['lavka', 'deli'] as StickerLogo[];
export const stickerLogoNames = makeServerTranslations(stickerLogoValues, 'enums.STICKER_LOGO');

export type StoreStatus =
	| 'imported'
	| 'searching'
	| 'searching_low'
	| 'agreement'
	| 'refused'
	| 'signed'
	| 'disabled'
	| 'active'
	| 'repair'
	| 'closed';
export const storeStatusValues = [
	'imported',
	'searching',
	'searching_low',
	'agreement',
	'refused',
	'signed',
	'disabled',
	'active',
	'repair',
	'closed',
] as StoreStatus[];
export const storeStatusNames = makeServerTranslations(storeStatusValues, 'enums.STORE_STATUS');

export type StoreEstatus =
	| 'processing'
	| 'inventory_begin'
	| 'inventory'
	| 'inventory_locked'
	| 'inventory_finish'
	| 'buy_stocks'
	| 'sell_stocks';
export const storeEstatusValues = [
	'processing',
	'inventory_begin',
	'inventory',
	'inventory_locked',
	'inventory_finish',
	'buy_stocks',
	'sell_stocks',
] as StoreEstatus[];
export const storeEstatusNames = makeServerTranslations(storeEstatusValues, 'enums.STORE_ESTATUS');

export type StoreDisabledReason =
	| 'no_electricity'
	| 'planed_maintenance'
	| 'flood'
	| 'equipment_broken'
	| 'no_internet'
	| 'employees_latency'
	| 'door_blocked'
	| 'fire_alarm'
	| 'police_raid'
	| 'employees_shortage'
	| 'courier_shortage'
	| 'store_opening'
	| 'store_closure'
	| 'waiting_for_buying_stocks';
export const storeDisabledReasonValues = [
	'no_electricity',
	'planed_maintenance',
	'flood',
	'equipment_broken',
	'no_internet',
	'employees_latency',
	'door_blocked',
	'fire_alarm',
	'police_raid',
	'employees_shortage',
	'courier_shortage',
	'store_opening',
	'store_closure',
	'waiting_for_buying_stocks',
] as StoreDisabledReason[];
export const storeDisabledReasonNames = makeServerTranslations(
	storeDisabledReasonValues,
	'enums.STORE_DISABLED_REASON'
);

export type StoreType = 'lavka' | 'dc' | 'dc_external' | 'lavka_retail';
export const storeTypeValues = ['lavka', 'dc', 'dc_external', 'lavka_retail'] as StoreType[];
export const storeTypeNames = makeServerTranslations(storeTypeValues, 'enums.STORE_TYPE');

export type UsersManage = 'internal' | 'external';
export const usersManageValues = ['internal', 'external'] as UsersManage[];
export const usersManageNames = makeServerTranslations(usersManageValues, 'enums.USERS_MANAGE');

export type StoreError =
	| 'shelf:store'
	| 'shelf:markdown'
	| 'shelf:incoming'
	| 'shelf:out'
	| 'shelf:trash'
	| 'shelf:kitchen_on_demand'
	| 'shelf:kitchen_components'
	| 'shelf:office'
	| 'shelf:found'
	| 'shelf:lost'
	| 'shelf:lost_and_found'
	| 'shelf:collection'
	| 'shelf:parcel'
	| 'shelf:parcel_returned'
	| 'shelf:kitchen_trash'
	| 'shelf:kitchen_menu'
	| 'shelf:kitchen_lost'
	| 'shelf:kitchen_found'
	| 'shelf:cargo'
	| 'shelf:repacking'
	| 'assortment:not_found'
	| 'assortment:none'
	| 'assortment:disabled'
	| 'assortment:removed'
	| 'markdown_assortment:not_found'
	| 'markdown_assortment:none'
	| 'markdown_assortment:disabled'
	| 'markdown_assortment:removed'
	| 'price_list:not_found'
	| 'price_list:none'
	| 'price_list:disabled'
	| 'price_list:removed'
	| 'product_group:not_found'
	| 'product_group:none'
	| 'product_group:disabled'
	| 'product_group:removed'
	| 'product_group:has_parent'
	| 'zone:not_found'
	| 'zone:not_active'
	| 'zone'
	| 'slug'
	| 'location'
	| 'printer:not_configured'
	| 'with_dark_kitchen:has_kitchen_assortment'
	| 'with_dark_kitchen:has_kitchen_coffee_schedule'
	| 'with_dark_kitchen:incorrect_dark_kitchen'
	| 'dark_kitchen:hasnt_kitchen_assortment'
	| 'dark_kitchen:kitchen_assortment_neq_assortment'
	| 'dark_kitchen:hasnt_kitchen_coffee_schedule'
	| 'dark_kitchen:has_zone';
export const storeErrorValues = [
	'shelf:store',
	'shelf:markdown',
	'shelf:incoming',
	'shelf:out',
	'shelf:trash',
	'shelf:kitchen_on_demand',
	'shelf:kitchen_components',
	'shelf:office',
	'shelf:found',
	'shelf:lost',
	'shelf:lost_and_found',
	'shelf:collection',
	'shelf:parcel',
	'shelf:parcel_returned',
	'shelf:kitchen_trash',
	'shelf:kitchen_menu',
	'shelf:kitchen_lost',
	'shelf:kitchen_found',
	'shelf:cargo',
	'shelf:repacking',
	'assortment:not_found',
	'assortment:none',
	'assortment:disabled',
	'assortment:removed',
	'markdown_assortment:not_found',
	'markdown_assortment:none',
	'markdown_assortment:disabled',
	'markdown_assortment:removed',
	'price_list:not_found',
	'price_list:none',
	'price_list:disabled',
	'price_list:removed',
	'product_group:not_found',
	'product_group:none',
	'product_group:disabled',
	'product_group:removed',
	'product_group:has_parent',
	'zone:not_found',
	'zone:not_active',
	'zone',
	'slug',
	'location',
	'printer:not_configured',
	'with_dark_kitchen:has_kitchen_assortment',
	'with_dark_kitchen:has_kitchen_coffee_schedule',
	'with_dark_kitchen:incorrect_dark_kitchen',
	'dark_kitchen:hasnt_kitchen_assortment',
	'dark_kitchen:kitchen_assortment_neq_assortment',
	'dark_kitchen:hasnt_kitchen_coffee_schedule',
	'dark_kitchen:has_zone',
] as StoreError[];
export const storeErrorNames = makeServerTranslations(storeErrorValues, 'enums.STORE_ERROR');

export type StoreLocationType = 'Feature';
export const storeLocationTypeValues = ['Feature'] as StoreLocationType[];
export const storeLocationTypeNames = makeServerTranslations(storeLocationTypeValues);

export type StoreLocationGeometryType = 'Point';
export const storeLocationGeometryTypeValues = ['Point'] as StoreLocationGeometryType[];
export const storeLocationGeometryTypeNames = makeServerTranslations(storeLocationGeometryTypeValues);

export type StoreSources = '1c' | 'wms' | 'woody';
export const storeSourcesValues = ['1c', 'wms', 'woody'] as StoreSources[];
export const storeSourcesNames = makeServerTranslations(storeSourcesValues, 'enums.STORE_SOURCES');

export type StoreAttrAgglomerationService = 'AD566' | 'AD578' | 'AA829';
export const storeAttrAgglomerationServiceValues = ['AD566', 'AD578', 'AA829'] as StoreAttrAgglomerationService[];
export const storeAttrAgglomerationServiceNames = makeServerTranslations(storeAttrAgglomerationServiceValues);

export type StoreCourierApplication = 'eats' | 'taximeter';
export const storeCourierApplicationValues = ['eats', 'taximeter'] as StoreCourierApplication[];
export const storeCourierApplicationNames = makeServerTranslations(
	storeCourierApplicationValues,
	'enums.STORE_COURIER_APPLICATION'
);

export type StoreTags = 'lavka_plus' | 'lavka_super' | 'lavka_offline' | 'dark_kitchen';
export const storeTagsValues = ['lavka_plus', 'lavka_super', 'lavka_offline', 'dark_kitchen'] as StoreTags[];
export const storeTagsNames = makeServerTranslations(storeTagsValues, 'enums.STORE_TAGS');

export type CourierWorkPermits = 'moscow_patent' | 'moscow_region_patent' | 'russian_federation_citizenship';
export const courierWorkPermitsValues = [
	'moscow_patent',
	'moscow_region_patent',
	'russian_federation_citizenship',
] as CourierWorkPermits[];
export const courierWorkPermitsNames = makeServerTranslations(courierWorkPermitsValues, 'enums.COURIER_WORK_PERMITS');

/* eslint-disable max-lines */

import { Row, Spin } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import { useCouriersBriefByExternalId } from '~cache/useCouriersBriefByExternalId';
import { orderStatusesList } from '~constants/order';
import {
	invoicePaymentMethodTypeNames,
	type OrderDeliveryStatus,
	orderDeliveryStatusNames,
	type OrderStatus,
	orderStatusNames,
} from '~server-types/doc/api/models/order';
import OrderModal from '~shared/components/OrdersHistory/OrderModal';
import TableHeader from '~shared/components/TableHeader';
import useGetDocumentsToProcess from '~shared/hooks/useGetDocumentsToProcess';
import useOrdersMonitor from '~shared/hooks/useOrdersMonitor';
import usePersistScrollPosition from '~shared/hooks/usePersistScrollPosition';
import useQueryParams from '~shared/hooks/useQueryParams';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import { ProTable } from '~shared/ui-kit/ProTable';
import type { TabsContentProps } from '~shared/ui-kit/Tabs';
import Tabs from '~shared/ui-kit/Tabs';
import { arrayUniq } from '~shared/utils/arrayUniq';
import columnSearch from '~shared/utils/columnSearch/';
import courierName from '~shared/utils/courierName';
import { filterOrder } from '~shared/utils/filterOrder';
import { isNotNullish } from '~shared/utils/isNotNullish';
import patchColumns from '~shared/utils/patchColumns';
import { sortOrdersData } from '~shared/utils/sortOrdersData';
import { useStyles } from '~styles/table';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';
import { useCheckExp, useCheckPermit, useCheckPermitGroup, useUser, useUserConstants } from '~zustand/userData';

import type { UserToShow } from '../UsersPage/UserModal';
import UserModal from '../UsersPage/UserModal';
import CollectsTable from './CollectsTab/CollectsTable';
import CompleteCollectsBtn from './CollectsTab/CompleteCollectsBtn';
import MakeWaveBtn from './MakeWaveBtn';
import { OrdersTab } from './OrdersTab';
import { dataTest, getConfig, getTableConfig, orderTableColumnsOrder, routes } from './pageConfig';
import { PrintCollectButton } from './PrintCollectButton';
import UnassignCourierModal from './UnassignCourier';
import { checkIsAcceptanceToApprove, checkIsDocumentToScan } from './Utils/filterDocuments';
import { filterOrdersForTable } from './Utils/filterOrdersForTable';
import { formatPlannedTime } from './Utils/plannedTime';
import useLoadPartnerPickupList from './Utils/useLoadPartnerPickupList';

export const filterOrders = (orders: Orders.Order[]) => orders.filter(filterOrder);

export const getCourierName = (
	order: Orders.Order,
	couriers: Record<Couriers.CourierExternalId, Couriers.CourierBrief | undefined>
) => {
	const courier = order.courier?.taxi_driver_uuid ? couriers?.[order.courier.taxi_driver_uuid] : undefined;
	const fullname = courierName(courier);

	return fullname !== '—' ? fullname : (order.courier?.name ?? '—');
};

interface Props {
	headerVisible?: boolean;
}

export interface SearchData {
	order_status: OrderStatus[] | undefined;
	eda_status: OrderDeliveryStatus | undefined;
	courier_name: string[] | undefined;
	pay_method: string[] | undefined;
	planned_time: string[] | undefined;
}

export const SUBORDERS = ['sub_order_kitchen', 'sub_order_coffee', 'sub_order_parcels', 'sub_order_units'];

const OrdersPage: FC<Props> = () => {
	const [t] = useTranslation();
	const params = useParams<{ store_id: string }>();
	const storeId = params.store_id!;
	const { classes } = useStyles();
	const [orderIdToShow, setOrderIdToShow] = useState<string | undefined>(undefined);
	const [userToShow, setUserToShow] = useState<UserToShow>();
	const [showUnassignCourierModal, toggleUnassignCourierModal] = useState<Orders.Order['order_id'] | undefined>(
		undefined
	);
	const [searchData, setSearchData] = useState<SearchData>({
		order_status: undefined,
		eda_status: undefined,
		courier_name: undefined,
		pay_method: undefined,
		planned_time: undefined,
	});
	const [selectedCollects, setSelectedCollects] = useState<string[]>([]);

	const isExpAgutin = useCheckExp('exp_agutin');
	const isExpFalcon = useCheckExp('exp_falcon');
	const isExpChuganin = useCheckExp('exp_chuganin');
	const isExpLolNami = useCheckExp('exp_lol_nami');
	const isExpPartnerPickupList = useCheckExp('exp_partner_pickup_list');
	const isPermitOrdersIncomplete = useCheckPermit('orders_incomplete'); // условие на показ незавершенных ордеров
	const isPermitGroupDispatcherApproveAcceptance = useCheckPermitGroup('group_order_approve_acceptance');
	const isPermitGroupOrderAttachOrderFiles = useCheckPermitGroup('group_order_attach_order_files');
	const isPermitGroupOrderMakeWave = useCheckPermitGroup('group_order_make_wave');
	const isPermitGroupOrderFinishCollect = useCheckPermitGroup('group_order_finish_collect');
	const isPermitDispatcherCreateOrder = useCheckPermit('dispatcher_create_order');
	const isPermitDispatcher = useCheckPermit('dispatcher');

	const constants = useUserConstants();
	const { ordersData } = useOrdersMonitor({
		storeId,
		loadIncomplete: isPermitOrdersIncomplete,
		timeZone: constants.store?.tz,
	});

	const user = useUser();

	usePersistScrollPosition('ordersPagePosition', 'html');

	const loaded: boolean | undefined = ordersData.loaded;

	const queryParams = useQueryParams('tab', { default: 'not_in_work' });

	const sortedOrdersData: Orders.Order[] = useMemo(() => sortOrdersData(ordersData.orders, true), [ordersData.orders]);

	const reqOrders = sortedOrdersData.filter((order) => {
		return (
			(order.status === orderStatusesList.reserving ||
				order.status === orderStatusesList.request ||
				order.status === orderStatusesList.approving) &&
			!SUBORDERS.includes(order.type)
		);
	});

	// ордера "В работе"

	const processedOrders = useMemo(
		() =>
			sortedOrdersData.filter(
				(order) => order.status === orderStatusesList.processing && !SUBORDERS.includes(order.type)
			),
		[sortedOrdersData]
	);

	// -----

	// ордера "С ошибками"

	const problemOrders = sortedOrdersData.filter((order) => {
		return (
			(order.problems.length > 0 ||
				order.status === orderStatusesList.canceled ||
				order.status === orderStatusesList.failed) &&
			!SUBORDERS.includes(order.type)
		);
	});
	problemOrders.sort((a, b) => {
		const weight = {
			[orderStatusesList.request]: 1,
			[orderStatusesList.approving]: 2,
			[orderStatusesList.reserving]: 3,
			[orderStatusesList.processing]: 4,
		};
		if (weight[a.status] !== weight[b.status]) {
			return weight[a.status] - weight[b.status];
		} else return 0;
	});

	// -----

	// ордера "Закрытые"

	const logOrders = sortedOrdersData.filter(
		(order) => order.status === orderStatusesList.complete && !SUBORDERS.includes(order.type)
	);

	// -----

	// приемки, требующие сканирования

	const loadedDocumentsToProcess = useGetDocumentsToProcess(isExpAgutin, [user.store_id]);

	const { stores } = useCache({
		stores: storeId,
	});

	const documentsToProcess: Orders.Order[] | undefined = useMemo(() => {
		return loadedDocumentsToProcess?.orders
			?.filter((order: Orders.Order, index) => {
				const isToScan = checkIsDocumentToScan({
					order,
					falconIsOn: isExpFalcon,
					chuganinIsOn: isExpChuganin,
				});

				const isToApprove = checkIsAcceptanceToApprove({
					order,
					timezone: stores[order?.store_id]?.tz,
					agutinIsOn: isExpAgutin,
					mayApprove: isPermitGroupDispatcherApproveAcceptance,
				});

				const isAcceptanceMarketToApprove =
					order.type === 'acceptance_market' &&
					isPermitGroupDispatcherApproveAcceptance &&
					order.attr?.documents_status === 'scan';

				const isOrderFilesNeedToScan =
					isPermitGroupOrderAttachOrderFiles &&
					['scan', 'rescan'].includes(order.attr?.documents_status) &&
					order.attr.order_files_schema === 'default';
				return (
					(isOrderFilesNeedToScan || isToScan || isToApprove || isAcceptanceMarketToApprove) &&
					loadedDocumentsToProcess?.orders.findIndex((item) => item.order_id === order.order_id) === index
				);
			})
			.sort((a, b) => (dayjs(b.doc_date).isAfter(a.doc_date) ? 1 : -1));
	}, [loadedDocumentsToProcess?.orders.length]);

	// -----

	// Заказы партнеров
	const partnerPickupListLoaded = useLoadPartnerPickupList(isExpPartnerPickupList, [user.store_id]);

	const cache = useCache({
		userExecutors: [
			...ordersData.orders.flatMap((order) => order.users),
			...partnerPickupListLoaded.orders.flatMap((order) => order.users),
			...(documentsToProcess ?? []).flatMap((order) => order.users || []),
		],
		orders: ordersData.orders.flatMap((order) => order.parent),
	});

	const couriersBriefByExternalId = useCouriersBriefByExternalId(
		ordersData.orders.map((order) => order.courier?.taxi_driver_uuid)
	);

	// ордера "Заказы"

	const orderOrders = filterOrdersForTable(ordersData.orders, searchData, couriersBriefByExternalId);

	// -----

	const tableConfig = getTableConfig(
		storeId,
		(order) => setOrderIdToShow(order.order_id),
		undefined,
		{
			order: setOrderIdToShow,
			user: setUserToShow,
		},
		['documents_comment', 'partner_name', 'otp_code', 'mark_as_taken', 'invoice_sum'],
		{
			orders: cache.orders,
			userExecutors: cache.userExecutors,
		}
	);

	const baseColumns = orderTableColumnsOrder({
		order: setOrderIdToShow,
		user: setUserToShow,
		unassignCourier: toggleUnassignCourierModal,
		storeCoords: user.store_id ? stores[user.store_id]?.location?.geometry?.coordinates : undefined,
		couriers: couriersBriefByExternalId,
		isLoading: cache.loading,
	});

	const getUniqCourierNames = () => {
		const result = arrayUniq(
			filterOrders(ordersData.orders).map((order) => getCourierName(order, couriersBriefByExternalId))
		)
			.filter(isNotNullish)
			.map((name) => {
				return {
					label: name,
					value: name,
				};
			});

		return [{ label: t('Не назначен'), value: 'Not assigned' }, ...result];
	};

	const getUniqPlannedTimeOptions = () => {
		const filtredOrders = ordersData.orders.filter((order) => Boolean(order?.attr?.time_slot));

		return arrayUniq(filtredOrders.map((order) => formatPlannedTime(order))).map((time) => ({
			label: time,
			value: time,
		}));
	};

	const hasOrdersWithTimeSlot = !ordersData.orders.some((order) => Boolean(order.attr?.time_slot));

	const searchProps = {
		status: columnSearch(
			searchData,
			setSearchData,
			'checkbox',
			Object.entries(orderStatusNames).map(([value, label]) => ({
				label,
				value,
			}))
		)('order_status'),
		eda_status: columnSearch(
			searchData,
			setSearchData,
			'checkbox',
			Object.entries(orderDeliveryStatusNames).map(([value, label]) => ({
				label,
				value,
			}))
		)('eda_status'),
		courier: columnSearch(searchData, setSearchData, 'checkbox', getUniqCourierNames())('courier_name'),
		invoice_sum: columnSearch(
			searchData,
			setSearchData,
			'checkbox',
			Object.entries(invoicePaymentMethodTypeNames).map(([value, label]) => ({
				label,
				value,
			}))
		)('pay_method'),
		planned_time: columnSearch(
			searchData,
			setSearchData,
			'checkbox',
			getUniqPlannedTimeOptions(),
			undefined,
			undefined,
			{ hide: hasOrdersWithTimeSlot }
		)('planned_time'),
	};

	const columns = patchColumns(baseColumns, searchProps);

	const tableConfigOrders = {
		...tableConfig,
		columns,
	};

	const collectOrders = useMemo(
		() => processedOrders.filter((order) => order.type === 'collect' && order.estatus === 'waiting_stocks'),
		[processedOrders]
	);

	const title = t('Диспетчерская');
	useSetTitle(title);

	const tabsContent: TabsContentProps = [
		{
			id: 'not_in_work',
			label: t('Не в работе'),
			badge: {
				count: reqOrders?.length ?? 0,
				view: 'default',
			},
			'data-testid': 'orders tab Не в работе',
			children: (
				<ProTable
					dataSource={reqOrders}
					{...tableConfig}
					data-test={dataTest.table.requested}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
		},
		{
			id: 'in_work',
			label: t('В работе'),
			badge: {
				count: processedOrders?.length ?? 0,
				view: 'default',
			},
			'data-testid': 'orders tab В работе',
			children: (
				<ProTable
					dataSource={processedOrders}
					{...tableConfig}
					data-test={dataTest.table.processed}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
		},
		{
			id: 'with_errors',
			label: t('С ошибками'),
			badge: {
				count: problemOrders?.length ?? 0,
				view: 'default',
			},
			'data-testid': 'orders tab С ошибками',
			children: (
				<ProTable
					dataSource={problemOrders}
					{...tableConfig}
					data-test={dataTest.table.wrong}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
		},
		{
			id: 'closed',
			label: t('Закрытые'),
			badge: {
				count: logOrders?.length ?? 0,
				view: 'default',
			},
			'data-testid': 'orders tab Закрытые',
			children: (
				<ProTable
					dataSource={logOrders}
					{...tableConfig}
					data-test={dataTest.table.complete}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
		},
		{
			id: 'orders',
			label: t('Заказы'),
			badge: {
				count: orderOrders?.length ?? 0,
				view: 'default',
			},
			'data-testid': 'orders tab Заказы',
			children: <OrdersTab orders={orderOrders} tableConfig={tableConfigOrders} />,
		},
		{
			id: 'need_scan',
			label: t('Требуется обработка'),
			badge: {
				count: documentsToProcess?.length ?? 0,
				view: 'default',
			},
			children: !loadedDocumentsToProcess?.orders ? (
				<Spin />
			) : (
				<ProTable
					dataSource={documentsToProcess?.length ? documentsToProcess : []}
					{...getTableConfig(
						storeId,
						(order) => setOrderIdToShow(order.order_id),
						undefined,
						{
							order: setOrderIdToShow,
							user: setUserToShow,
						},
						['partner_name', 'otp_code', 'mark_as_taken'],
						{
							orders: cache.orders,
							userExecutors: cache.userExecutors,
							user,
						}
					)}
					data-test={dataTest.table.wrong}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
			condition: isExpAgutin || isExpFalcon || isExpChuganin || isPermitGroupOrderAttachOrderFiles,
		},
		{
			id: 'collects',
			label: t('Волновая сборка'),
			badge: {
				count: collectOrders?.length ?? 0,
				view: 'default',
			},
			children: <CollectsTable orders={collectOrders} tableConfig={tableConfig} setSelection={setSelectedCollects} />,
			condition: isExpLolNami,
		},
		{
			id: 'partner_pickup_list',
			label: t('Заказы партнеров'),
			badge: {
				count: partnerPickupListLoaded.orders?.length ?? 0,
				view: 'default',
			},
			children: (
				<ProTable
					dataSource={partnerPickupListLoaded.orders}
					{...getTableConfig(
						storeId,
						(order) => setOrderIdToShow(order.order_id),
						undefined,
						{
							order: setOrderIdToShow,
							user: setUserToShow,
						},
						['documents_comment'],
						{
							orders: cache.orders,
							userExecutors: cache.userExecutors,
							user,
						}
					)}
					data-test={dataTest.table.pickup}
					showNoAccessIfNoData={isPermitDispatcher}
				/>
			),
			condition: isExpPartnerPickupList,
		},
	];

	return (
		<Row className={classes.container}>
			<OrderModal setOrderIdToShow={setOrderIdToShow} orderIdToShow={orderIdToShow} />
			<UserModal userToShow={userToShow} setUserToShow={setUserToShow} />
			{showUnassignCourierModal && (
				<UnassignCourierModal
					orderId={showUnassignCourierModal as Orders.Order['order_id']}
					closeModal={() => toggleUnassignCourierModal(undefined)}
				/>
			)}
			<TableHeader
				key={1}
				title={getConfig().tableTitle}
				primaryButtons={[
					{
						type: 'component',
						component: (
							<PrintCollectButton
								orders={collectOrders.filter((e) => selectedCollects.includes(e.order_id))}
								buttonText={t('Печать заказ-нарядов')}
							/>
						),
						condition: isPermitGroupOrderMakeWave && !!selectedCollects.length,
					},
					{
						type: 'component',
						component: <CompleteCollectsBtn collects={selectedCollects} setCollectsToClose={setSelectedCollects} />,
						condition: isPermitGroupOrderFinishCollect && !!selectedCollects.length,
					},
					{
						type: 'component',
						component: <MakeWaveBtn />,
						condition: isPermitGroupOrderMakeWave,
					},
					{
						type: 'link',
						to: routes.add(storeId),
						dataTest: dataTest.table.add,
						text: getConfig().btns.add,
						condition: isPermitDispatcherCreateOrder,
					},
				]}
			/>
			{loaded ? (
				<Tabs
					className={classes.tabs}
					activeTab={queryParams.active}
					onSelectTab={queryParams.setActive}
					tabsContent={tabsContent}
					destroyInactiveTabPane={true}
				/>
			) : (
				<Spin />
			)}
		</Row>
	);
};

export default OrdersPage;

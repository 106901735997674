import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

import TableauLogoutPage from '../components/MonitorTableau/Blocks/TvPages/LogoutPage';
import TableauCrushPage from '../components/MonitorTableau/Blocks/TvPages/TableauCrushPage';
import TvAuth from '../components/MonitorTableau/Blocks/TvPages/TvAuth';

const MonitorTableau = lazy(() => import('../components/MonitorTableau'));

const tableau = makeRoutes([
	{
		path: '/tableau',
		permitOneOf: ['monitor_orders'],
		element: <MonitorTableau />,
	},
	{
		path: '/tv-client/tableau/crush',
		element: <TableauCrushPage />,
	},
	{
		path: '/tv-client/tableau/auth',
		element: <TvAuth />,
	},
	{
		path: '/tv-client/tableau',
		element: <MonitorTableau />,
		permitOneOf: ['device_access'],
		accessDeniedElement: <TableauLogoutPage />,
	},
]);

export default tableau;

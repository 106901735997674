import type { Entity, Id } from '~cache/cache';
import { useCacheStore } from '~cache/cache';
import type { SectionConfig } from '~constants/dataPageFields/types';
import type { CountryCode } from '~server-types/doc/api/models/country';
import { isNotNullish } from '~shared/utils/isNotNullish';
import type { Products, TrueMark } from '~types/products';

export const getProductWeight = (product: Products.Product | undefined): number | undefined => {
	if (product?.vars?.imported) {
		const { brutto_weight, netto_weight } = product.vars.imported;
		return brutto_weight ?? netto_weight;
	}
	return undefined;
};

export const getItemWeight = (item: Products.Item | undefined): number | undefined => {
	if (item?.data) {
		return item?.data?.weight;
	}
	return undefined;
};

async function refreshProducts(entity: Entity<'products'>) {
	await useCacheStore.getState().refreshData('products', entity, true);
	const data = useCacheStore.getState().products?.data ?? {};

	return (Array.isArray(entity?.ids) ? entity?.ids : ([entity?.ids] ?? []))
		.map((id: Id) => data[id ?? ''])
		.filter(isNotNullish);
}

export async function fetchProductsData(entity: Entity<'products'>) {
	return {
		data: await refreshProducts(entity),
	};
}

export const getTrueMark = (country: CountryCode | undefined): TrueMark => {
	return country === 'UZB' ? 'Asl Belgisi' : 'Честный Знак';
};

export const getTrueMarkProductsField = (productFields: SectionConfig[], country_code: CountryCode | undefined) => {
	const id = productFields[0]?.fields.findIndex((item, index) => {
		return item.key === 'vars.imported.true_mark' ? index : undefined;
	});
	if (!id) return productFields;

	const field = { ...productFields[0]?.fields[id], label: getTrueMark(country_code) };
	productFields[0].fields[id] = field;

	return productFields;
};

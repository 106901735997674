import { useStyles } from './styles';

type Props = {
	x: number;
	y: number;
	value: number;
	formatter?: (value: number) => string;
};

const Tick = ({ x, y, value, formatter }: Props) => {
	const { classes } = useStyles();
	const text = formatter ? formatter(value) : value.toString();
	const splittedText = text?.split(' ');

	if (!splittedText || !splittedText.length) return '—';

	const res = [];
	for (let i = 0; i < splittedText.length - 1; i += 4) {
		res.push(
			<text x={x} y={y + i * 4} dy={0} className={classes.yAxisTick}>
				{splittedText.slice(i, i + 4).join(' ')}
			</text>
		);
	}
	return res;
};

export default Tick;

import { useMemo } from 'react';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';
import { arrayUniq } from '~shared/utils/arrayUniq';
import { isNotNullish } from '~shared/utils/isNotNullish';
import { useCheckPermit } from '~zustand/userData';

import type { Couriers } from './../types/couriers';
import { useCache } from './useCache';

const COURIER_EXTERNAL_ID_REGEXP = /^\S+_\S+$/;

export type Id = Couriers.CourierExternalId | null | undefined;

export const useCouriersBriefByExternalId = (ids: Id | Id[]) => {
	const isPermitCouriersLoad = useCheckPermit('couriers_load');
	const { addData, couriersBrief } = useCache({
		couriersBrief: [],
	});

	const idsArray = Array.isArray(ids) ? ids : [ids];

	const idsFiltered = useMemo(() => {
		return arrayUniq(idsArray).filter(
			(id) => isNotNullish(id) && COURIER_EXTERNAL_ID_REGEXP.test(id)
		) as Couriers.CourierExternalId[];
	}, [idsArray.join('')]);

	const couriersBriefByExternalId: Record<Couriers.CourierExternalId, Couriers.CourierBrief | undefined> =
		useMemo(() => {
			const result: Omit<typeof couriersBrief, 'loading'> = {};

			for (const id in couriersBrief) {
				if (couriersBrief[id]) {
					result[couriersBrief[id]!.external_id] = couriersBrief[id];
				}
			}

			return result;
		}, [couriersBrief]);

	const idsToLoad = useMemo(() => {
		return idsFiltered.filter((id) => !couriersBriefByExternalId[id]);
	}, [idsFiltered]);

	useLoadData(
		() => api.couriers.loadBriefByExternalId({ external_id: idsToLoad }),
		[idsToLoad],
		!isPermitCouriersLoad || !idsToLoad.length,
		(data) => {
			addData({
				entityName: 'couriersBrief',
				data: data.result,
			});
		}
	);

	return useMemo(() => {
		// @ts-expect-error
		const result: typeof couriersBrief = idsArray.length === 0 ? couriersBriefByExternalId : {};

		Object.defineProperty(result, 'loading', {
			value: couriersBrief.loading,
			enumerable: false,
		});

		if (idsArray.length === 0) {
			return result;
		}

		idsFiltered.forEach((id) => {
			if (couriersBriefByExternalId[id]) {
				result[id] = couriersBriefByExternalId[id];
			}
		});

		return result;
	}, [idsFiltered, couriersBriefByExternalId, idsArray.join('')]);
};

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const AdjustmentsDataPage = lazy(() => import('../components/Adjustments/AdjustmentsDataPage/index'));
const AdjustmentsPage = lazy(() => import('../components/Adjustments'));
const AdjustmentsImportDataPage = lazy(() => import('../components/Adjustments/Import/ImportDataPage'));

const adjustmentsRoutes = makeRoutes([
	{
		path: '/compensations_adjustments',
		permitOneOf: ['adjustments_seek'],
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsPage />,
	},
	{
		path: '/compensations_adjustments/add',
		permitOneOf: ['adjustments_create'],
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage editMode addMode />,
	},
	{
		path: '/compensations_adjustments/edit/:adjustment_id',
		permitOneOf: ['adjustments_save'],
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage editMode />,
	},
	{
		path: '/compensations_adjustments/:adjustment_id',
		permitOneOf: ['adjustments_load'],
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsDataPage />,
	},
	{
		path: '/compensations_imports/:adjustments_import_id',
		permitOneOf: ['adjustments_imports_load'],
		exp: 'exp_activate_wms_adjustments',
		element: <AdjustmentsImportDataPage />,
	},
]);

export default adjustmentsRoutes;

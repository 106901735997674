import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const MarkingsPage = lazy(() => import('../components/MarkingsPage'));

const markingsRoutes = makeRoutes([
	{
		path: '/markings',
		permitOneOf: ['true_marks_load'],
		element: <MarkingsPage />,
	},
]);

export default markingsRoutes;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type { SchetTaskHandlerType } from '~constants/dataPageFields/schetTaskFields';
import { schetTaskBasicFields } from '~constants/dataPageFields/schetTaskFields';
import type * as FieldTypes from '~constants/dataPageFields/types';
import IdReplacer from '~shared/components/IdReplacer';
import { useCheckPermit } from '~zustand/userData';

import { SchetsDataPage } from './Base';

type Props = {
	addMode?: boolean;
	editMode?: boolean;
};

const handlerFieldOptions: SchetTaskHandlerType[] = [
	'create_check_valid',
	'create_writeoff',
	'sync_products',
	'update_vat',
	'sync_price_lists',
	'robot_replenishment',
];

export const StoreSchetsDataPage = (props: Props) => {
	const [t] = useTranslation();
	const isPermitStoresSeek = useCheckPermit('stores_seek');
	const params = useParams<{ store_id: string }>();

	useCache({
		companies: {
			ids: params.store_id,
			_fields: ['title'],
		},
	});

	const baseFields = useMemo(() => {
		const fields = schetTaskBasicFields;

		const storeField = getField(fields, t('Основное'), 'store_id') as FieldTypes.TextField | undefined;
		if (storeField) {
			storeField.hidden = false;
			storeField.defaultValue = params.store_id;
			storeField.render = (value: string) => (
				<IdReplacer
					id={value}
					type="stores"
					keyReplaceOn="title"
					link={isPermitStoresSeek ? `/stores/${value}` : undefined}
				/>
			);
		}

		const scopeField = getField(fields, t('Основное'), 'scope');
		if (scopeField) {
			scopeField.defaultValue = 'store';
		}

		return fields;
	}, [params.store_id]);

	const baseUrl = `/stores/${params.store_id}/schets`;
	return (
		<SchetsDataPage
			{...props}
			isStore={true}
			baseUrl={baseUrl}
			baseFields={baseFields}
			handlerFieldOptions={handlerFieldOptions}
		/>
	);
};

import { makeRoutes } from '~shared/utils/makeRoutes';

import { SupportSearch } from './index';

export default makeRoutes([
	{
		path: '/pomidorka',
		element: <SupportSearch />,
		permitOneOf: ['group_suptools_search'],
	},
]);

import { matchRoutes } from 'react-router-dom';
import { router } from 'router';

import type { RouteObjectType } from '~shared/utils/makeRoutes';
import { checkExp, checkPermissions } from '~zustand/userData';

import { logError } from './logs';

export const checkRoute = (path: string) => {
	const matchedRoutes = matchRoutes(router.routes, path);
	const matchedRoute = matchedRoutes?.find((route) => route.pathname === path);

	if (!matchedRoute) {
		logError({ path }, new Error('Matched route not found'));
		return false;
	}

	const {
		meta: { permitOneOf, exp },
	} = matchedRoute.route as RouteObjectType;

	return checkPermissions(permitOneOf) && checkExp(exp);
};

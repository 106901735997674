import dayjs from 'dayjs';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UnifiedMultiSelect from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect';
import { Field, Form } from '~shared/components/Forms';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import NewbieAlert from '~shared/components/MenteeMentorField/MenteeAlert';
import type { UserData } from '~shared/components/MenteeMentorField/MentorsField';
import { assign } from '~shared/components/MenteeMentorField/utils';
import ModalComponent from '~shared/components/ModalComponent';
import userName from '~shared/utils/userName';

const AssignMentor = ({
	userId,
	usersList,
	visible,
	setModalVisible,
	updateRerenderKey,
	mentorId,
	editUser,
}: {
	userId?: string;
	usersList?: UserData[];
	setModalVisible: (value: boolean) => void;
	visible: boolean;
	updateRerenderKey: () => void;
	mentorId?: string;
	editUser?: UserData;
}) => {
	const [t] = useTranslation();
	const form = useForm();
	const { handleSubmit, reset, control } = form;
	const selectedValue = useWatch({ control, name: 'user_id' });
	const [dictionary, setDictionary] = useState<Record<string, ReactElement>>({});
	const [cancelModalVisible, setCancelModalVisible] = useState(false);
	const [searchData, setSearchData] = useState({ title: undefined });
	const searchUser = (values: typeof searchData) => {
		setSearchData({
			...values,
		});
	};

	useEffect(() => {
		setDictionary(
			usersList?.reduce((map: Record<string, any>, executor: UserData) => {
				if (userName(executor).includes(searchData.title ?? '') && executor.user_id !== mentorId) {
					map[executor.user_id!] = userName(executor, { showDisplayName: true });
				}
				return map;
			}, {}) ?? {}
		);
	}, [selectedValue, searchData, visible]);

	const onSubmit = async (value: FieldValues) => {
		await assign({
			notificationText: { success: t('Наставник назначен'), error: t('Не удалось назначить ментора') },
			updateRerenderKey,
			setModalVisible,
			reset,
			userId: userId ?? '',
			mentorId: value.user_id,
		});
	};

	const startDate = editUser?.vars?.mentoring_since ? editUser?.vars.mentoring_since : editUser?.created;
	const selectMentorDeadline = dayjs(startDate)
		.add(MILESTONES.canAssign - 1, 'day')
		.format('DD.MM.YYYY');

	return (
		<>
			<ModalComponent
				open={cancelModalVisible && !mentorId}
				onCancel={() => {
					setCancelModalVisible(false);
					setModalVisible(true);
				}}
				onOk={() => {
					setCancelModalVisible(false);
					setModalVisible(false);
					reset();
				}}
				title={t('Отменить выбор наставника?')}
			>
				{t('Вы сможете выбрать наставника на странице сотрудника до {{date}} включительно', {
					date: selectMentorDeadline,
				})}
			</ModalComponent>
			<ModalComponent
				title={t('Выберите наставника')}
				open={visible}
				onOk={handleSubmit(onSubmit)}
				onCancel={() => {
					setModalVisible(false);
					setCancelModalVisible(true);
				}}
				okText={t('Применить')}
				destroyOnClose={true}
				width={600}
				okButtonProps={{
					disabled: !selectedValue,
				}}
				data-test="assign mentor modal"
			>
				{!selectedValue && !mentorId && <NewbieAlert />}
				<Form key="mentee_form" hookForm={form}>
					<Field
						key="user_id"
						name="user_id"
						dataTest="user_id"
						label={t('Наставник')}
						editMode
						allowClear
						colspan={{
							xl: { span: 12 },
							lg: { span: 12 },
							sm: { span: 12 },
							xs: { span: 12 },
						}}
						component={UnifiedMultiSelect}
						setSearchData={searchUser}
						showSearch={true}
						placeholder={t('Выберите сотрудника')}
						options={Object.keys(dictionary ?? {})}
						dictionary={dictionary}
					/>
				</Form>
			</ModalComponent>
		</>
	);
};

export default AssignMentor;

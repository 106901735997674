import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const StoresEquipment = lazy(() => import('../components/Equipment/StoresEquipment'));

const storesEquipment = makeRoutes([
	{
		path: '/stores_equipment',
		permitOneOf: ['equipments_load'],
		exp: 'exp_lavka4',
		element: <StoresEquipment />,
	},
]);

export default storesEquipment;

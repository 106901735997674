import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const AnalyticsPage = lazy(() => import('../components/AnalyticsPage'));

const analytics = makeRoutes([
	{
		path: '/analytics',
		permitOneOf: ['store_analytics', 'courier_analytics'],
		element: <AnalyticsPage />,
	},
]);

export default analytics;

import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Timetable } from '~types/common';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<
		{
			store_id?: string | string[];
			status?: Zones.Zone['status'] | Zones.Zone['status'][];
			delivery_type?: Zones.Zone['delivery_type'] | Zones.Zone['delivery_type'][];
			now?: string;
			box?: Stores.Box;
		},
		Zones.Zone
	>('/api/admin/zones/list'),
	load: makeLoadFn<Zones.Zone, 'zone_id'>('/api/admin/zones/load', 'zone_id'),
	save: (data: Zones.ZoneSaveParams) => axios.post<{ result: Zones.Zone }>('/api/admin/zones/save', data),

	approve: (data: { zone_id: string; status: Zones.Zone['status'] }) =>
		axios.post<{ result: Zones.Zone }>('/api/admin/zones/approve', data),

	status: (data: { zone_id: string; status: Zones.Zone['status']; timetable?: Timetable[]; effective_from?: string }) =>
		axios.post('/api/admin/zones/status', data),
};

import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import type { PathsListPostEntity } from '~server-types/analytics/doc/api/admin/store_healths';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';
import userName from '~shared/utils/userName';
import type { Stores } from '~types/stores';

import { defaultGraphsState, sourceByType } from '../constants';
import type { CurrentEntityType, HealthLevelMetrics, HealthMetricsListResponse, PeriodState } from '../healthTypes';
import type { ViewData } from '../index';
import { loadHealthData } from '../utils/loadHealthData';
import { getComparisonHealthRequests, getDatesFromSlice } from '../utils/period';

type Args = {
	levelState: ViewData;
	periodState: PeriodState;
	timezone?: Stores.Cluster['tz'] | null;
	subscriptionKey: string;
};

export interface StoresTableData extends HealthLevelMetrics {
	title?: string;
	cluster_title?: string;
}

interface StoresListTableData extends HealthLevelMetrics {
	store_id: string;
}

interface ClustersListTableData extends HealthLevelMetrics {
	cluster_id: string;
}

const subUsersType = {
	company: 'cluster',
	cluster: 'head_supervisor',
	head_supervisor: 'supervisor',
	supervisor: 'store',
	store: null,
} as const;

const useHealthData = ({ levelState, periodState, timezone, subscriptionKey }: Args) => {
	const [t] = useTranslation();
	const [mainLoading, toggleMainLoading] = useState(false);
	const cache = useCache({
		stores: [],
		companies: [],
		clusters: [],
		userExecutors: [],
	});

	const [rawData, setRawData] = useState<{ now?: HealthMetricsListResponse; past?: HealthMetricsListResponse }>({});

	// Сырые данные + данные для графиков
	useEffect(() => {
		if (!periodState || !levelState.id || !levelState.type || timezone === undefined) return;

		const { start, end } = getDatesFromSlice({ sliceType: periodState.sliceType, tz: timezone ?? undefined });
		const { now, past } = getComparisonHealthRequests(start, end, periodState.comparisonType, levelState);
		const { past: pastForGraph } = getComparisonHealthRequests(
			start,
			dayjs()
				.tz(timezone ?? undefined)
				.endOf('day')
				.format(),
			periodState.comparisonType,
			levelState
		);

		void (async () => {
			toggleMainLoading(true);
			try {
				const data = await loadHealthData({ now, past });
				if (periodState.sliceType === 'hour') {
					setRawData(data);
					return;
				}
				const dataForGraphs = await loadHealthData({ past: pastForGraph });
				setRawData({ ...data, past: { ...data.past, graphs: dataForGraphs.past?.graphs } });
			} catch {
				notification.error({ message: t('Не удалось загрузить данные') });
			} finally {
				toggleMainLoading(false);
			}
		})();
	}, [levelState.id, levelState.type, periodState.comparisonType, periodState.sliceType, timezone, subscriptionKey]);

	const updated = rawData.now?.updated;

	const currentEntity: CurrentEntityType = useMemo(() => {
		const entity = rawData.now?.metrics?.[levelState.type]?.[levelState.id ?? ''];
		return {
			...entity,
			...levelState,
		};
	}, [rawData, levelState]);

	const subUsers = useMemo(() => {
		let subType = subUsersType[levelState.type];
		if (!subType) {
			return [];
		}

		if (subType === 'head_supervisor' && isObjectEmpty(rawData?.now?.metrics?.[subType])) {
			subType = 'supervisor';
		}

		const { id_slice, titleProp } = sourceByType[subType];
		return Object.keys(rawData?.now?.metrics?.[subType] ?? {}).map((id: string) => ({
			id,
			type: subType as PathsListPostEntity,
			title:
				titleProp === 'fullname' ? userName(cache[id_slice][id]) || '—' : (cache[id_slice][id]?.[titleProp] ?? '—'),
		}));
	}, [rawData, cache.userExecutors, cache.clusters, cache.stores, levelState]);

	const stats = useMemo(
		() => ({
			now: rawData?.now?.metrics?.[levelState.type ?? '']?.[levelState.id ?? ''] ?? {},
			past: rawData?.past?.metrics?.[levelState.type ?? '']?.[levelState.id ?? ''] ?? {},
		}),
		[rawData]
	);

	const graphs = useMemo(() => {
		if (levelState?.type && levelState?.id) {
			return {
				now: rawData?.now?.graphs?.[levelState.type]?.[levelState.id ?? ''] ?? [],
				past: rawData?.past?.graphs?.[levelState.type]?.[levelState.id ?? ''] ?? [],
			};
		}
		return defaultGraphsState;
	}, [rawData, levelState]);

	const storesTableData = useMemo(() => {
		let updData: StoresListTableData[] = [];
		if (rawData?.now?.metrics?.store) {
			updData = Object.values(rawData.now.metrics.store).map((entry: HealthLevelMetrics) => ({
				...entry,
				store_id: entry.store_id!,
				title: cache.stores[entry.store_id!]?.title ?? '—',
			}));
		}
		return updData;
	}, [rawData, cache.stores]);

	const clustersTableData = useMemo(() => {
		let updData: ClustersListTableData[] = [];
		if (rawData?.now?.metrics?.cluster) {
			updData = Object.entries(rawData.now.metrics.cluster).map(([id, entry]) => ({
				...entry,
				cluster_id: id,
				cluster_title: cache.clusters[id ?? '']?.title ?? '—',
			}));
		}
		return updData;
	}, [rawData, cache.clusters]);

	useCache({
		stores: [...Object.keys(rawData?.now?.metrics?.store ?? {}), currentEntity?.store_id],
		companies: currentEntity?.company_id,
		clusters: Object.keys(rawData?.now?.metrics?.cluster ?? {}),
		userExecutors: [
			...Object.keys(rawData?.now?.metrics?.head_supervisor ?? {}),
			...Object.keys(rawData?.now?.metrics?.supervisor ?? {}),
			currentEntity?.head_supervisor_id,
			currentEntity?.supervisor_id,
		],
	});

	return {
		mainLoading,
		rawData,
		graphs,
		storesTableData,
		clustersTableData,
		stats,
		subUsers,
		currentEntity,
		updated,
	};
};

export default useHealthData;

import QRCode from 'qrcode';
import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';

export default function (
	canvasId: string,
	options: { scale?: number; text?: string; width?: number },
	deps: DependencyList = []
) {
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		if (options.text) {
			try {
				void QRCode.toCanvas(document.getElementById(canvasId), options.text, {
					scale: options.scale ?? 4,
					width: options.width,
					margin: 0,
				});
			} catch {
				setError('Некорректный формат баркода!');
			}
		}
	}, deps);

	return {
		error,
	};
}

import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as StarIcon } from '~images/icons/star-icon.svg';
import { useStyles } from '~shared/components/MenteeMentorField/styles';

const NewbieAlert = () => {
	const { classes } = useStyles();
	const [t] = useTranslation();

	return (
		<Alert
			className={classes.alert}
			icon={<StarIcon height={16} width={16} className={classes.alertIcon} />}
			message={
				<>
					<div className={classes.alertHeader}>{t('Этот кладовщик - новичок')}</div>
					<div className={classes.alertText}>{t('Нужна помощь наставника с адаптацией')}</div>
				</>
			}
			type="success"
			showIcon
			data-test="mentor info alert assign"
		/>
	);
};

export default NewbieAlert;

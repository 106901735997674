import type { RefinementCtx } from 'zod';
import { z } from 'zod';

import i18n from '~/i18n';

const emailRegex = /[\w.-]+(@|%40)[\w.-]+\.(ru|com)/;
const phoneRegex = /(?:^|\s)(\+|%2B)?[7|8]([ -]?[0-9][ -]?){10,13}(?:\s|$)/;
// Для расч счета или кор счета
const accountRegex = /[0-9]{20}/;

export const validateNotPd = (val: string | null | undefined, ctx: RefinementCtx, path?: string) => {
	if (!val) {
		return;
	}
	if (emailRegex.test(val) || (phoneRegex.test(val) && !accountRegex.test(val))) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			path: path ? [`${path}`] : undefined,
			message: i18n.t('Проверьте правильность введенных данных'),
		});
	}
};

import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ShiftPreferencesDataPage = lazy(
	() => import('../components/CouriersPage/ShiftPreferences/ShiftPreferencesDataPage')
);

const shiftPreferencesRoutes = makeRoutes([
	{
		path: '/stores/:store_id/couriers/:courier_id/shift_preferences/add',
		permitOneOf: ['couriers_save'],
		exp: ['exp_visible_courier_shift_preferences', 'exp_b2b_edit_preferences'],
		element: <ShiftPreferencesDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/couriers/:courier_id/shift_preferences/edit/:weekday_number',
		permitOneOf: ['couriers_save'],
		exp: ['exp_visible_courier_shift_preferences', 'exp_b2b_edit_preferences'],
		element: <ShiftPreferencesDataPage editMode />,
	},
	{
		path: '/stores/:store_id/couriers/:courier_id/shift_preferences/:weekday_number',
		permitOneOf: ['couriers_load'],
		exp: ['exp_visible_courier_shift_preferences'],
		element: <ShiftPreferencesDataPage />,
	},
	{
		path: '/couriers/:courier_id/shift_preferences/add',
		permitOneOf: ['couriers_save'],
		exp: ['exp_visible_courier_shift_preferences', 'exp_b2b_edit_preferences'],
		element: <ShiftPreferencesDataPage editMode addMode />,
	},
	{
		path: '/couriers/:courier_id/shift_preferences/edit/:weekday_number',
		permitOneOf: ['couriers_save'],
		exp: ['exp_visible_courier_shift_preferences', 'exp_b2b_edit_preferences'],
		element: <ShiftPreferencesDataPage editMode />,
	},
	{
		path: '/couriers/:courier_id/shift_preferences/:weekday_number',
		permitOneOf: ['couriers_load'],
		exp: ['exp_visible_courier_shift_preferences'],
		element: <ShiftPreferencesDataPage />,
	},
]);

export default shiftPreferencesRoutes;

import { notification } from '@lavka/ui-kit';
import type { AxiosAdditionalConfig } from 'api';
import type { AxiosResponse } from 'axios';

import i18n from '~/i18n';

import mergeResponses from './mergeResponses';

export default async function getAllRecursive<TRes extends { cursor: string | null }>(
	api: (cursor: string | null, config?: AxiosAdditionalConfig) => Promise<AxiosResponse<TRes>>,
	config?: AxiosAdditionalConfig,
	maxDepth?: number,
	onDepthOverflow?: () => void
): Promise<AxiosResponse<TRes>> {
	const responses: AxiosResponse<TRes>[] = [];
	let cursor: string | null = '';
	let currentDepth = 0;

	while (cursor !== null && (maxDepth === undefined || currentDepth <= maxDepth)) {
		if (config?.signal?.aborted) {
			throw { data: {}, status: 'CANCELED' };
		}

		if (currentDepth === maxDepth) {
			if (onDepthOverflow) {
				onDepthOverflow();
			} else {
				notification.warning({
					message: i18n.t('Превышен максимально допустимый уровень вложенности {{count}}', { count: maxDepth }),
				});
			}
		}

		const response: AxiosResponse<TRes> = await api(cursor, config);
		responses.push(response);
		cursor = cursor === response.data.cursor ? null : response.data.cursor;
		currentDepth++;
	}

	if (responses.length) {
		const result = responses.shift()!;
		responses.forEach((res) => mergeResponses<TRes>(result, res));

		return result;
	}

	throw new Error();
}

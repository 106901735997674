import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { User, UserRoles } from '~types/user';

import { makeLoadFn } from './storage/makeLoadFn';

type Role = {
	role: string;
	permits?: User.Permit;
};

export default {
	list: makeListFn<
		{
			fullname?: string;
			store_id?: string;
			role?: UserRoles;
			provider?: string;
			email?: string;
			phone?: string;
			nick?: string;
			limit?: number;
			status?: 'active' | 'disabled';
			last_name?: string;
		},
		User.User
	>('/api/admin/users/list'),
	load: makeLoadFn<User.User, 'user_id'>('/api/admin/users/load', 'user_id'),
	executors: (data: { user_id: string | string[] }, config?: AxiosAdditionalConfig) =>
		axios
			.post<{ executors: User.Executor[]; result?: User.Executor[] }>('/api/admin/users/executors', data, config)
			.then((resp) => ({ ...resp, data: { result: resp.data.result ?? resp.data.executors } })),
	save: (data: User.UserUpdate) => axios.post<{ result: User.User }>('/api/admin/users/save', data),
	create: (data: User.UserCreate) => axios.post<{ result: User.User }>('/api/admin/users/save', data),

	permits: (data: { roles?: string[] }) => axios.post<{ result: Role[] }>('/api/admin/users/permits', data),

	seek_phone: (data: { phone?: string; cursor: string | null; store_empty: boolean }, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: User.User[] }>('/api/admin/users/seek_phone', data, config),

	eject: (data: { user_id?: string }) => axios.post<{ result: User.User }>('/api/admin/users/eject', data),

	loadPd: (data: { data_type: 'email' | 'phone'; user_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: string | null }>('/api/admin/users/load_pd', data, config),

	list_allowed: (data: { role: UserRoles; store_id: string }) =>
		axios.post<{ result: User.User[] }>('/api/admin/users/list_allowed', data),

	mentor_assigned: (data: { mentor_id: string; cursor?: string | null }) =>
		axios.post<{ result: User.User[]; cursor: string }>('/api/admin/users/mentor/assigned', data),

	mentor_assign: (data: { user_id: string; mentor_id: string | null }) =>
		axios.post<{ result: User.User[] }>('/api/admin/users/mentor/assign', data),
};

/* eslint-disable max-lines */

import { notification } from '@lavka/ui-kit';
import { Select, Spin } from 'antd';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { clustersMapData } from '~constants/other';
import { ReactComponent as LinkRegular } from '~images/icons/link-regular.svg';
import { storeStatusNames, storeStatusValues } from '~server-types/doc/api/models/store';
import { zoneDeliveryTypesNames } from '~server-types/doc/api/models/zone';
import { eZoneDeliveryTypesValues } from '~shared/components/Fields/StoreGeoData/StoreMap/utils';
import PrimaryButtonComponent from '~shared/components/PrimaryButtonComponent';
import ResetFiltersButton from '~shared/components/ResetFiltersButton';
import TableHeader from '~shared/components/TableHeader';
import useLoadData from '~shared/hooks/useLoadData';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import { useYandexMapDarkTheme } from '~shared/hooks/useYandexMapDarkTheme';
import { useYandexMapsScript } from '~shared/hooks/useYandexMapsScript';
import copyToClipboard from '~shared/utils/copyToClipboard';
import getAllRecursive from '~shared/utils/getAllRecursive';
import Icon from '~shared/utils/icons';
import type { ExpName } from '~types/userConfig';
import { useCheckPermit, useUser, useUserConstants } from '~zustand/userData';

import type { ClusterGeometryFields } from './constants';
import {
	CLUSTER_FIELDS_TO_LOAD,
	initialOptionsToShowState,
	initialOtherOptionsState,
	initialStatusToShowState,
	initialZonesToShowState,
	otherOptions,
	otherOptionsDictionary,
	RETRY_AMOUNT,
} from './constants';
import FilterGroup from './FilterComponents/FilterGroup';
import MapLegend from './MapLegend';
import { useStyles } from './styles';
import useAddStoresToMap from './useAddStoresToMap';
import type { ClusterGeometry, ZoneBrief } from './utils';
import { boundsChangeSubscription, getClusterGeometry, getZonelessClusterGeometry } from './utils';

declare let ymaps: any;

interface MapState {
	center?: [number, number];
	zoom?: number;
}

const StoresMap = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const map = useRef<any>();
	const mapContainer = useRef<HTMLDivElement>(null);
	const refreshButton = useRef<any>();
	const mapRowRef = useRef<any>();

	const constants = useUserConstants();
	const user = useUser();

	const isPermitClustersSeek = useCheckPermit('clusters_seek');

	const clusterData = useLoadData(
		() => api.clusters.list({ company_id: user.company_id, _fields: CLUSTER_FIELDS_TO_LOAD }),
		[user.company_id, isPermitClustersSeek],
		!user.company_id || !isPermitClustersSeek
	);

	const storeOptions = constants.configs?.exp;
	const userStoreStatuses = storeStatusValues.filter(
		(status) => Array.isArray(constants.store?.statuses) && constants.store?.statuses.includes(status)
	);

	const storeOptionsDictionary = useMemo(() => {
		return (Object.keys(constants.configs?.exp ?? {}) as ExpName[]).reduce(
			(acc, option) => {
				acc[option] = option;
				return acc;
			},
			{} as Partial<Record<ExpName, ExpName>>
		);
	}, [constants.configs?.exp]);

	const [otherOptionsToShow, setOtherOptionsToShow] = useState<string[]>(initialOtherOptionsState);

	const [statusesToShow, setStatusesToShow] = useState<string[]>(initialStatusToShowState);

	const [zonesToShow, setZonesToShow] = useState<string[]>(initialZonesToShowState);

	const [optionsToShow, setOptionsToShow] = useState<string[]>(initialOptionsToShowState);

	const resetFiltersToDefault = () => {
		setOtherOptionsToShow(initialOtherOptionsState);
		setStatusesToShow(initialStatusToShowState);
		setZonesToShow(initialZonesToShowState);
		setOptionsToShow(initialOptionsToShowState);
	};

	const allStores = useAddStoresToMap({
		map: map.current,
		mapType: 'yandex',
		options: {
			statusesToShow,
			zonesToShow,
			optionsToShow,
			otherOptionsToShow,
		},
		zoneStatuses: ['active'],
	});

	useYandexMapsScript();

	useLayoutEffect(() => {
		mapRefresh(getMapState());
	}, [mapRowRef.current?.offsetHeight, mapRowRef.current?.offsetWidth]);

	const { clusters } = useCache({
		clusters: {
			ids: [constants.store?.cluster_id],
			_fields: CLUSTER_FIELDS_TO_LOAD,
		},
	});

	const userCluster = constants.store?.cluster_id ? clusters[constants.store?.cluster_id] : undefined;
	const userClusterGeometry = useRef<ClusterGeometry | null>(null);

	useEffect(() => {
		if (userCluster) {
			const clusterGeometry = getClusterGeometry(userCluster);

			if (clusterGeometry) {
				userClusterGeometry.current = clusterGeometry;
			}
		}
	}, [userCluster]);

	const [selectedClusterTitle, setSelectedClusterTitle] = useState<string>();
	const retryCounter = useRef<number>(0);

	const waitForElement = (mapState: any) => {
		// Ждём, когда карта инициализируется и будет определён кластер пользователя.
		// Количество попыток определить кластер ограничено чтобы не блокировать отображение карты в случае ошибки
		if (
			typeof ymaps !== 'undefined' &&
			mapRowRef.current?.offsetWidth &&
			(userClusterGeometry.current || retryCounter.current >= RETRY_AMOUNT)
		) {
			ymaps.ready(() => mapInit(mapState));
		} else {
			retryCounter.current++;
			setTimeout(() => {
				waitForElement(mapState);
			}, 500);
		}
	};

	// обновление карты
	function mapRefresh(mapState: MapState = {}) {
		if (mapRowRef.current?.offsetHeight) {
			map.current?.destroy();

			try {
				waitForElement(mapState);
			} catch {
				notification.error({
					message: t('Не удалось инициализировать карту'),
				});
			}
		}
	}

	function getMapState() {
		if (map.current) {
			return {
				center: map.current.getCenter(),
				zoom: map.current.getZoom(),
			};
		}
		return {};
	}

	function getParam(name: any) {
		const res = window.location.hash.match(new RegExp('[#&]' + name + '=([^&]*)', 'i'));
		return res && res[1] ? res[1] : false;
	}

	// инициализация карты
	function mapInit(mapState: MapState = {}) {
		map.current?.destroy();

		let center;
		let zoom;
		const hashCenter = getParam('center');
		const hashZoom = getParam('zoom');

		if (hashCenter && hashZoom) {
			center = hashCenter.split(',');
			zoom = hashZoom;
		} else if (mapState.center && mapState.zoom) {
			center = mapState.center;
			zoom = mapState.zoom;
		} else if (userClusterGeometry.current?.center && userClusterGeometry.current?.zoom) {
			center = userClusterGeometry.current.center;
			zoom = userClusterGeometry.current.zoom;
			setSelectedClusterTitle(userClusterGeometry.current.title);
		} else {
			center = clustersMapData['Москва'].center;
			zoom = clustersMapData['Москва'].zoom;
		}

		map.current = new ymaps.Map('stores-map', {
			center,
			zoom,
		});

		refreshButton.current = new ymaps.control.Button({
			data: {
				content: t('Обновить'),
			},
			options: {
				maxWidth: 'medium',
				size: 'medium',
				selectOnClick: false,
			},
		});
		refreshButton.current.events.add('press', function () {
			allStores.clearRenderedStores();
			mapRefresh(getMapState());
		});

		map.current.controls.add(refreshButton.current, {
			float: 'right',
		});
		boundsChangeSubscription(map.current, allStores.setBounds);
	}

	function createMapStateLink(): string {
		const state = getMapState();
		return `${window.location.origin}/map#center=${state.center}&zoom=${state.zoom}`;
	}

	function copyMapStateLink() {
		try {
			copyToClipboard(createMapStateLink());
			notification.success({
				message: t('Ссылка на карту скопирована в буфер обмена'),
			});
		} catch {
			notification.error({
				message: t('Не удалось получить ссылку на карту'),
			});
		}
	}

	const [clustersList, setClustersList] = useState<ClusterGeometry[]>([]);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			const clustersWithClusterGeometry: ClusterGeometry[] = userClusterGeometry.current
				? [userClusterGeometry.current]
				: [];
			const clustersWithoutClusterGeometry: ClusterGeometryFields[] = [];
			const clusters = clusterData.data?.results ?? (userCluster ? [userCluster] : []);

			clusters.forEach((cluster) => {
				if (cluster.cluster_id !== userClusterGeometry.current?.id) {
					const clusterGeometry: ClusterGeometry | null = getClusterGeometry(cluster);

					if (clusterGeometry) {
						clustersWithClusterGeometry.push(clusterGeometry);
					} else {
						clustersWithoutClusterGeometry.push(cluster);
					}
				}
			});

			setClustersList(clustersWithClusterGeometry);

			if (!clustersWithoutClusterGeometry.length) {
				return;
			}

			const clustersWithStoresGeometry: ClusterGeometry[] = [];
			const clustersData: Record<string, { cluster: ClusterGeometryFields; zones: ZoneBrief[] }> = {};
			const storesMap: Record<string, string> = {};

			for (const cluster of clustersWithoutClusterGeometry) {
				try {
					const { data: storesData } = await getAllRecursive(
						(cursor, config) =>
							api.stores.list(
								{
									cluster_id: cluster.cluster_id,
									_fields: ['store_id'],
									cursor,
								},
								config
							),
						{ signal: abortController.signal }
					);

					clustersData[cluster.cluster_id] = {
						cluster,
						zones: [],
					};

					storesData.results.forEach((store) => {
						storesMap[store.store_id] = cluster.cluster_id;
					});
				} catch (e) {
					if (e.status !== 'CANCELED') {
						notification.error({
							message: t('Не удалось получить склады'),
						});
					}
				}
			}

			const storeIds = Object.keys(storesMap);

			if (!storeIds.length) {
				return;
			}

			try {
				const { data: zonesData } = await getAllRecursive(
					(cursor, config) =>
						api.zones.list(
							{
								store_id: storeIds,
								_fields: ['store_id', 'zone'],
								cursor,
							},
							config
						),
					{ signal: abortController.signal }
				);

				zonesData.results.forEach((zone) => {
					const clusterId = storesMap[zone.store_id];
					clustersData[clusterId].zones.push(zone);
				});
			} catch (e) {
				if (e.status !== 'CANCELED') {
					notification.error({
						message: t('Не удалось получить зоны'),
					});
				}
			}

			for (const clusterId in clustersData) {
				const geometry = getZonelessClusterGeometry(clustersData[clusterId].cluster, clustersData[clusterId].zones) ?? {
					title: clustersData[clusterId].cluster.title,
					id: clustersData[clusterId].cluster.cluster_id,
				};

				clustersWithStoresGeometry.push(geometry);
			}

			setClustersList((prev) => [...prev, ...clustersWithStoresGeometry]);
		})();

		return () => {
			abortController.abort();
		};
	}, [userClusterGeometry.current, clusterData.data?.results, userCluster]);

	const changeCenter = (clusterId: string) => {
		setSelectedClusterTitle(clusterId);
		const chosenCluster = clustersList.find((cluster) => cluster.id === clusterId);

		if (chosenCluster?.center) {
			map.current.setCenter(chosenCluster.center, chosenCluster.zoom, {
				checkZoomRange: true,
			});
		} else {
			notification.error({
				message: t('Кластер не имеет зон'),
			});
		}
	};

	const title = t('Карта');
	useSetTitle(title);

	useYandexMapDarkTheme(map.current, [map]);

	return (
		<>
			<div className={classes.mapControls}>
				<Select
					data-test="stores map cluster selector"
					style={{
						inlineSize: 170,
					}}
					value={selectedClusterTitle}
					onSelect={changeCenter}
				>
					{clustersList?.map((cluster) => (
						<Select.Option key={cluster?.id} value={cluster?.id}>
							{cluster?.title}
						</Select.Option>
					))}
				</Select>
				{allStores.isLoading && <Spin className={classes.spin} />}
				<FilterGroup
					filters={[
						{
							key: 'statuses',
							filterTitle: t('Статусы'),
							allValues: userStoreStatuses,
							valuesToShow: statusesToShow,
							onChange: setStatusesToShow,
							dictionary: storeStatusNames,
						},
						{
							key: 'zones',
							filterTitle: t('Типы зон'),
							allValues: eZoneDeliveryTypesValues,
							valuesToShow: zonesToShow,
							onChange: setZonesToShow,
							dictionary: zoneDeliveryTypesNames,
						},
						{
							key: 'options',
							filterTitle: t('Опции'),
							allValues: storeOptions ? Object.keys(storeOptions) : [],
							valuesToShow: optionsToShow,
							onChange: setOptionsToShow,
							dictionary: storeOptionsDictionary,
						},
						{
							key: 'other',
							filterTitle: t('Другое'),
							allValues: otherOptions,
							valuesToShow: otherOptionsToShow,
							onChange: setOtherOptionsToShow,
							dictionary: otherOptionsDictionary,
						},
					]}
				/>
				<PrimaryButtonComponent
					button={{
						type: 'button',
						action: () => copyMapStateLink(),
						icon: <Icon component={LinkRegular} />,
						tooltip: { title: t('Копировать ссылку') },
						className: 'ant-btn',
						dataTest: 'copy map state link button',
					}}
				/>
				<ResetFiltersButton
					hasDivider
					resetFunction={resetFiltersToDefault}
					tooltip={t('Сбросить фильтры по умолчанию')}
				/>
			</div>
			<div className={classes.mapRow} ref={mapRowRef}>
				<TableHeader title={title} />
				<div
					ref={mapContainer}
					id="stores-map"
					data-test="stores map"
					style={{
						inlineSize: mapRowRef.current?.offsetWidth,
						blockSize: mapRowRef.current?.offsetHeight,
					}}
				/>
			</div>
			<MapLegend />
		</>
	);
};

export default StoresMap;

// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type ShelfTypes =
	| 'incoming'
	| 'out'
	| 'trash'
	| 'lost'
	| 'found'
	| 'store'
	| 'markdown'
	| 'office'
	| 'parcel'
	| 'parcel_returned'
	| 'collection'
	| 'cargo'
	| 'repacking'
	| 'review'
	| 'kitchen_on_demand'
	| 'kitchen_components'
	| 'kitchen_trash'
	| 'kitchen_lost'
	| 'kitchen_found'
	| 'store_robot';
export const shelfTypesValues = [
	'incoming',
	'out',
	'trash',
	'lost',
	'found',
	'store',
	'markdown',
	'office',
	'parcel',
	'parcel_returned',
	'collection',
	'cargo',
	'repacking',
	'review',
	'kitchen_on_demand',
	'kitchen_components',
	'kitchen_trash',
	'kitchen_lost',
	'kitchen_found',
	'store_robot',
] as ShelfTypes[];
export const shelfTypesNames = makeServerTranslations(shelfTypesValues, 'enums.SHELF_TYPES');

export type ShelfTags = 'refrigerator' | 'freezer' | 'freezer2_2' | 'domestic' | 'freezer24' | 'safe';
export const shelfTagsValues = [
	'refrigerator',
	'freezer',
	'freezer2_2',
	'domestic',
	'freezer24',
	'safe',
] as ShelfTags[];
export const shelfTagsNames = makeServerTranslations(shelfTagsValues, 'enums.SHELF_TAGS');

export type ShelfStatuses = 'active' | 'removed' | 'disabled';
export const shelfStatusesValues = ['active', 'removed', 'disabled'] as ShelfStatuses[];
export const shelfStatusesNames = makeServerTranslations(shelfStatusesValues, 'enums.SHELF_STATUSES');

import { makeRoutes } from '~shared/utils/makeRoutes';

import { CompanySchets } from '../components/Schets/Company';
import { CompanySchetsDataPage, StoreSchetsDataPage } from '../components/Schets/SchetsDataPage';
import { StoreSchets } from '../components/Schets/Store';

const schetsRoutes = makeRoutes([
	{
		path: '/stores/:store_id/schets',
		permitOneOf: ['schets_seek', 'group_schet_task_create'],
		element: <StoreSchets />,
	},
	{
		path: '/stores/:store_id/schets/add',
		permitOneOf: ['schets_create'],
		element: <StoreSchetsDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/schets/edit/:schet_task_id',
		permitOneOf: ['schets_save'],
		element: <StoreSchetsDataPage editMode />,
	},
	{
		path: '/stores/:store_id/schets/:schet_task_id',
		permitOneOf: ['schets_load'],
		element: <StoreSchetsDataPage />,
	},
	{
		path: '/companies/:company_id/schets',
		permitOneOf: ['schets_seek', 'group_schet_task_create'],
		element: <CompanySchets />,
	},
	{
		path: '/companies/:company_id/schets/add',
		permitOneOf: ['schets_create'],
		element: <CompanySchetsDataPage editMode addMode />,
	},
	{
		path: '/companies/:company_id/schets/edit/:schet_task_id',
		permitOneOf: ['schets_save'],
		element: <CompanySchetsDataPage editMode />,
	},
	{
		path: '/companies/:company_id/schets/:schet_task_id',
		permitOneOf: ['schets_load'],
		element: <CompanySchetsDataPage />,
	},
]);

export default schetsRoutes;

import type { TableProps } from 'antd';
import { Alert, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { ColumnType } from 'antd/lib/table';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyTableState from '~shared/components/EmptyState/EmptyTableState';

import { useStyles } from './styles';

type PossiblePairs<T extends object> =
	| {
			[K in keyof T]: { dataIndex: K; render?: (text: T[K], data: T, index: number) => ReactNode };
	  }[keyof T]
	| { dataIndex?: never; render?: (value: T, data: T, index: number) => ReactNode };

export type ProColumns<T extends object = any> = Omit<ColumnType<T>, 'dataIndex' | 'render'> &
	PossiblePairs<T> & {
		hideInTable?: boolean | (() => boolean);
		children?: ProColumns<T>[];
	};

export type ProTableProps<T extends object> = Omit<TableProps<T>, 'columns'> & {
	columns: ProColumns<T>[];
	tableAlertRender?: (value: NonNullable<TableProps<T>['rowSelection']>) => ReactNode;
	showNoAccessIfNoData?: boolean;
};

export function ProTable<T extends object = any>(props: ProTableProps<T>) {
	const {
		className,
		columns,
		tableAlertRender,
		style,
		pagination = false,
		showNoAccessIfNoData = true,
		locale,
		...restProps
	} = props;

	const { cx, classes } = useStyles();
	const [t] = useTranslation();

	const modifiedColumns = columns
		.filter((e) => (typeof e.hideInTable === 'function' ? !e.hideInTable() : !e.hideInTable))
		.map((column) => ({
			...column,
			children: column.children?.filter((e) =>
				typeof e.hideInTable === 'function' ? !e.hideInTable() : !e.hideInTable
			),
		}));

	return (
		<div className={className} style={style}>
			{!!props.rowSelection?.selectedRowKeys?.length && (
				<Alert
					type="info"
					message={
						tableAlertRender && props.rowSelection
							? tableAlertRender(props.rowSelection)
							: t('Выбрано {{count}} элементов', { count: props.rowSelection.selectedRowKeys.length })
					}
				/>
			)}
			<Table
				className={cx(classes.table, props.className)}
				columns={modifiedColumns as ColumnsType<T>}
				pagination={pagination}
				{...restProps}
				locale={
					showNoAccessIfNoData
						? locale
						: {
								emptyText: <EmptyTableState title={t('Недостаточно прав для просмотра данных')} />,
							}
				}
			/>
		</div>
	);
}

ProTable.Summary = Table.Summary;

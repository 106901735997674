import { languages } from './consts';
import type { Language } from './types';

export const getMatchedLanguage = (lang: Language) => {
	const [languagePrefix] = lang.split('-');
	const matchedLanguage = languages.find((language) => language.startsWith(`${languagePrefix}_`));

	return {
		isMatched: matchedLanguage !== undefined,
		language: matchedLanguage ? matchedLanguage?.replace('-', '_') : 'en_US',
	};
};

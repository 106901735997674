import { notification } from '@lavka/ui-kit';
import { Button, Spin } from 'antd';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { errorsCode } from '~constants/errorsCode';
import { useCheckPermitGroup } from '~zustand/userData';

interface Props {
	checkProjectLoading: boolean;
	visible: boolean;
	failedOrdersExists: boolean;
	checkProjectId: string;
}

const DefibrillationRegrading = ({ checkProjectLoading, visible, failedOrdersExists, checkProjectId }: Props) => {
	const [t] = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const isPermitGroupCheckProjectDefibrillate = useCheckPermitGroup('group_check_project_defibrillate');

	const startDefibrillation = async () => {
		try {
			setLoading(true);
			await api.checkProjects.defibrillation({ check_project_id: checkProjectId });

			notification.warning({
				message: t('Перезапуск документов Рекласса займёт какое-то время, но ты можешь заниматься другими задачами'),
			});
		} catch (data) {
			notification.error({
				message: errorsCode[data.code] ?? t('Не удалось перезапустить документы'),
			});
		} finally {
			setLoading(false);
		}
	};

	return visible ? (
		<Button
			ghost
			disabled={!(isPermitGroupCheckProjectDefibrillate && failedOrdersExists)}
			onClick={startDefibrillation}
		>
			{loading || checkProjectLoading ? <Spin /> : t('Перезапустить невыполненные документы')}
		</Button>
	) : null;
};

export default memo(DefibrillationRegrading);

import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const ShiftsPage = lazy(() => import('../components/Shifts'));
const ShiftsDirector = lazy(() => import('../components/Shifts/ShiftsDirector'));
const ShiftsDataPage = lazy(() => import('../components/Shifts/ShiftsDataPage'));
const ShiftsScheduleDataPage = lazy(() => import('../components/Shifts/ShiftImports/ShiftImportsDataPage'));

const shiftsRoutes = makeRoutes([
	{
		path: '/shifts',
		permitOneOf: ['courier_shifts_seek'],
		element: <ShiftsPage />,
	},
	{
		path: '/shifts/add',
		permitOneOf: ['courier_shifts_create'],
		element: <ShiftsDataPage editMode addMode />,
	},
	{
		path: '/shifts/edit/:shift_id',
		permitOneOf: ['courier_shifts_save'],
		element: <ShiftsDataPage editMode />,
	},
	{
		path: '/shifts/:shift_id',
		permitOneOf: ['courier_shifts_load'],
		element: <ShiftsDataPage />,
	},
	{
		path: '/stores/:store_id/shifts',
		permitOneOf: ['courier_shifts_seek'],
		element: <ShiftsPage />,
	},
	{
		path: '/stores/:store_id/shifts/add',
		permitOneOf: ['courier_shifts_create'],
		element: <ShiftsDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/shifts/edit/:shift_id',
		permitOneOf: ['courier_shifts_save'],
		element: <ShiftsDataPage editMode />,
	},
	{
		path: '/stores/:store_id/shifts/:shift_id',
		permitOneOf: ['courier_shifts_load'],
		element: <ShiftsDataPage />,
	},
	{
		path: '/shifts-director',
		permitOneOf: ['courier_shifts_seek'],
		exp: 'exp_alfred_pennyworth',
		element: <PrependPath />,
	},
	{
		path: '/stores/:store_id/shifts-director',
		permitOneOf: ['courier_shifts_seek'],
		element: <ShiftsDirector />,
	},
	{
		path: '/shifts/imports/:import_id',
		permitOneOf: ['courier_shift_schedules_load'],
		element: <ShiftsScheduleDataPage />,
	},
	{
		path: '/shifts/imports/edit/:import_id',
		permitOneOf: ['courier_shift_schedules_save'],
		element: <ShiftsScheduleDataPage editMode />,
	},
]);

export default shiftsRoutes;

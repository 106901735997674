import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ProductsTable = lazy(() => import('../components/ProductsPage'));
const ProductsDataPage = lazy(() => import('../components/ProductsPage/ProductsDataPage/ProductDataPage'));
const ItemDataPage = lazy(() => import('../components/ProductsPage/ProductsDataPage/ItemDataPage'));

const productsRoutes = makeRoutes([
	{
		path: '/products',
		permitOneOf: ['products_seek', 'group_product_create'],
		element: <ProductsTable listType="flat" title="" />,
	},
	{
		path: '/products/add',
		permitOneOf: ['products_create'],
		element: <ProductsDataPage editMode addMode />,
	},
	{
		path: '/products/edit/:product_id',
		permitOneOf: ['products_save'],
		element: <ProductsDataPage editMode />,
	},
	{
		path: '/products/:product_id',
		permitOneOf: ['products_load'],
		element: <ProductsDataPage />,
	},
	{
		path: '/products/ext/:external_id',
		permitOneOf: ['products_load'],
		element: <ProductsDataPage />,
	},
	{
		path: '/items/:item_id',
		permitOneOf: ['products_load'],
		element: <ItemDataPage />,
	},
]);

export default productsRoutes;

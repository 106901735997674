import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const UsersTable = lazy(() => import('../components/UsersPage'));
const UsersDataPage = lazy(() => import('../components/UsersPage/UsersDataPage'));

const usersRoutes = makeRoutes([
	{
		path: '/users',
		permitOneOf: ['users_seek', 'group_user_make_invite', 'group_user_create', 'group_store_qr_view'],
		element: <UsersTable />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/add',
		permitOneOf: ['users_create'],
		element: <UsersDataPage editMode addMode />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/edit/:user_id',
		permitOneOf: ['users_save'],
		element: <UsersDataPage editMode />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/:user_id',
		permitOneOf: ['users_load'],
		element: <UsersDataPage />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/stores/:store_id/users',
		permitOneOf: ['users_seek'],
		element: <UsersTable />,
	},
	{
		path: '/stores/:store_id/users/add',
		permitOneOf: ['users_create'],
		element: <UsersDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/users/edit/:user_id',
		permitOneOf: ['users_save'],
		element: <UsersDataPage editMode />,
	},
	{
		path: '/stores/:store_id/users/:user_id',
		permitOneOf: ['users_load'],
		element: <UsersDataPage />,
	},
]);

export default usersRoutes;

import { Spin } from 'antd';
import type { JSX } from 'react';
import { Suspense } from 'react';
import type { RouteObject } from 'react-router/dist/lib/context';

import { RouterErrorBoundary } from '~shared/components/ErrorBoundary/RouterErrorBoundary';
import { ProtectedComponent } from '~shared/components/ProtectedComponent';
import { RouteCheckerComponent } from '~shared/components/RouteCheckerComponent';
import type { ExpName } from '~types/userConfig';

export type RouteConfig = {
	path: string;
	permitOneOf?: string[];
	exp?: ExpName | ExpName[];
	accessDeniedElement?: JSX.Element;
	ensureStoreIdExists?: boolean;
	element: JSX.Element;
	disableRouteChecker?: boolean;
	alternativeElement?: JSX.Element;
};

type RouteObjectMetaType = {
	permitOneOf?: string[];
	exp?: ExpName | ExpName[];
};

export type RouteObjectType = RouteObject & { meta: RouteObjectMetaType };

export function makeRoutes(configs: RouteConfig[]): RouteObjectType[] {
	return configs.map((config) => {
		const { element, path, permitOneOf, disableRouteChecker, exp, ...restProps } = config;

		const routeElement = (
			<Suspense fallback={<Spin />}>
				<RouteCheckerComponent disableRouteChecker={disableRouteChecker}>
					{permitOneOf || exp ? (
						<ProtectedComponent permitOneOf={permitOneOf} element={element} exp={exp} {...restProps} />
					) : (
						element
					)}
				</RouteCheckerComponent>
			</Suspense>
		);

		return {
			path,
			element: routeElement,
			errorElement: <RouterErrorBoundary />,
			meta: {
				permitOneOf,
				exp,
			},
		};
	});
}

import { makeRoutes } from '~shared/utils/makeRoutes';

import Health from '../components/Health';

const health = makeRoutes([
	{
		path: '/health_store',
		permitOneOf: ['health_store'],
		element: <Health myStore />,
	},
	{
		path: '/health',
		permitOneOf: ['health_store'],
		element: <Health />,
	},
]);

export default health;

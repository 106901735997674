import { ProTable } from '~shared/ui-kit/ProTable';
import type { Orders } from '~types/orders';
import { useCheckPermit } from '~zustand/userData';

import { dataTest } from './pageConfig';

interface Props {
	orders: Orders.Order[];
	tableConfig: any;
}

const OrdersTable = ({ orders, tableConfig }: Props) => {
	const isPermitDispatcher = useCheckPermit('dispatcher');
	return (
		<ProTable
			dataSource={orders}
			{...tableConfig}
			data-test={dataTest.table.orders}
			canAccessData={isPermitDispatcher}
		/>
	);
};

export default OrdersTable;
